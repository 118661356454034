<template>
  <div>
    <el-affix :offset="60">
      <el-row  class="dis-cen-cen">
        <div  class=" dis-sta-cen" style="font-size: 14px; height: 46px;width: 100%;background-color: #FDF6EC">
          <div class="container dis-sta-cen" v-if="vipLevelChange === '00010002'">
            <p style="color: #999999;display: inline;">
              在您提交数据前，请先核对平台已有数据，以防提交重复信息，如有疑问请查看
            </p>
            <p style="color: #5D6FE9;display: inline;cursor: pointer;" @click="handleOpenPDF">
              操作手册
            </p>
            <p style="color: #999999;display: inline;">
              。VIP会员平台审核周期1-2个工作日。
            </p>
          </div>
          <div class="container dis-sta-cen" v-else>
            <p style="color: #999999;display: inline;">
              在您提交数据前，请先核对平台已有数据，以防提交重复信息，如有疑问请查看
            </p>
            <p style="color: #5D6FE9;display: inline;cursor: pointer;" @click="handleOpenPDF">
              操作手册
            </p>
            <p style="color: #999999;display: inline;">
              。普通会平台审核周期7-10个工作日，可开通VIP加速审核。
            </p>
            <p type="primary" @click="handleToVip()" class="btn-style">
              一键加速
            </p>
          </div>
        </div>
      </el-row>
    </el-affix>
    <div style="margin-top: 74px;">
      <el-row>
        <div class="container">
          <!--资质证书-->
          <div style="margin-bottom: 30px;" class="condition-content">
            <div class="dis-bet-cen condition-title">
              <span class="screening-conditions-title" style="font-size: 16px;color: #666666;">资质证书</span>
              <span v-if="tags.credential.length > 0" class="screening-conditions-title" style="font-size: 14px;color: #999999;">预计可提升{{ tags.credential.length * 20 }}分，实际提升以审核为准。</span>
            </div>
            <el-row v-if="tags.credential.length > 0" class="dis-cen-sta" style="margin-top: 10px;">
              <el-col :span="2"><div class="grid-content bg-purple font-choose-text">已上传资料：</div></el-col>
              <el-col :span="20">
                <div class="grid-content bg-purple dis-sta-cen" style="flex-wrap: wrap;">
                  <el-tag style="margin-right: 10px;margin-bottom: 10px;cursor:pointer;" v-for="(tag, index) in tags.credential" :key="index" :closable="tag.submitState === '1' ? false : true" :type="getTagsType(tag.submitState)" @close="closeTag(tag, index)" @click="clickTag(tag, index)">
                    资质证书：{{ tag.credentialName }}&nbsp;&nbsp;共上传了{{ tag.fileList.length ? tag.fileList.length : 0 }}个文件 <span v-if="tag.submitState === '1'" class="icon iconfont">&#xe60e;</span>
                  </el-tag>
                </div>
              </el-col>
              <el-col :span="2">
                <div @click="closeAll('credential', true)" class="grid-content bg-purple font-choose-text">清除全部</div>
              </el-col>
            </el-row>
            <el-row class="dis-sta-sta magin-top-bottom">
              <el-col :span="24" style="">
                <el-button size="medium" class="uploadButton" type="primary" @click="credentialUpload">
                  <p style="font-size: 14px;color: #FFFFFF;"><i style="font-size: 12px;" class="iconfont">&#xe711;</i>添加</p>
                </el-button>
              </el-col>
            </el-row>
          </div>
          <!--荣誉证书-->
          <div style="margin-bottom: 30px;" class="condition-content">
            <div class="dis-bet-cen condition-title">
              <span class="screening-conditions-title" style="font-size: 16px;color: #666666;">荣誉证书</span>
              <span v-if="tags.honorImage.length > 0" class="screening-conditions-title" style="font-size: 14px;color: #999999;">预计可提升{{ tags.honorImage.length * 8 }}分，实际提升以审核为准。</span>
            </div>
            <el-row v-if="tags.honorImage.length > 0" class="dis-cen-sta" style="margin-top: 10px;">
              <el-col :span="2"><div class="grid-content bg-purple font-choose-text">已上传资料：</div></el-col>
              <el-col :span="20">
                <div class="grid-content bg-purple dis-sta-cen" style="flex-wrap: wrap;">
                  <el-tag style="margin-right: 10px;margin-bottom: 10px;cursor:pointer;" v-for="(tag, index) in tags.honorImage" :key="index" :closable="tag.submitState === '1' ? false : true" :type="getTagsType(tag.submitState)" @close="closeTag(tag, index)" @click="clickTag(tag, index)">
                    荣誉证书：{{tag.name}}&nbsp;&nbsp;共上传了{{ tag.fileList.length ? tag.fileList.length : 0 }}个文件 <span v-if="tag.submitState === '1'" class="icon iconfont">&#xe60e;</span>
                  </el-tag>
                </div>
              </el-col>
              <el-col :span="2">
                <div @click="closeAll('honorImage')" class="grid-content bg-purple font-choose-text">清除全部</div>
              </el-col>
            </el-row>
            <el-row class="dis-sta-sta magin-top-bottom">
              <el-col :span="24" style="">
                <el-button size="medium" class="uploadButton" type="primary" @click="honorImageUpload">
                  <p style="font-size: 14px;color: #FFFFFF;"><i style="font-size: 12px;" class="iconfont">&#xe711;</i>添加</p>
                </el-button>
              </el-col>
            </el-row>
          </div>
          <!--技术人员-->
          <div style="margin-bottom: 30px;" class="condition-content">
            <div class="dis-bet-cen condition-title">
              <span class="screening-conditions-title" style="font-size: 16px;color: #666666;">技术人员</span>
              <span v-if="tags.registeredPersonnel.length > 0" class="screening-conditions-title" style="font-size: 14px;color: #999999;">预计可提升{{ tags.registeredPersonnel.length * 5 }}分，实际提升以审核为准。</span>
            </div>
            <el-row v-if="tags.registeredPersonnel.length > 0" class="dis-cen-sta" style="margin-top: 10px;">
              <el-col :span="2"><div class="grid-content bg-purple font-choose-text">已上传资料：</div></el-col>
              <el-col :span="20">
                <div class="grid-content bg-purple dis-sta-cen" style="flex-wrap: wrap;">
                  <el-tag style="margin-right: 10px;margin-bottom: 10px;cursor:pointer;" v-for="(tag, index) in tags.registeredPersonnel" :key="index" :closable="tag.submitState === '1' ? false : true" :type="getTagsType(tag.submitState)" @close="closeTag(tag, index)" @click="clickTag(tag, index)">
                    技术人员：{{tag.name}}&nbsp;&nbsp;共上传了{{ tag.fileList.length ? tag.fileList.length : 0 }}个文件 <span v-if="tag.submitState === '1'" class="icon iconfont">&#xe60e;</span>
                  </el-tag>
                </div>
              </el-col>
              <el-col :span="2">
                <div @click="closeAll('credential', true)" class="grid-content bg-purple font-choose-text">清除全部</div>
              </el-col>
            </el-row>
            <el-row class="dis-sta-sta magin-top-bottom">
              <el-col :span="24" style="">
                <el-button size="medium" class="uploadButton" type="primary" @click="registeredPersonnelUpload">
                  <p style="font-size: 14px;color: #FFFFFF;"><i style="font-size: 12px;" class="iconfont">&#xe711;</i>添加</p>
                </el-button>
              </el-col>
            </el-row>
          </div>
          <!--项目业绩-->
          <div style="margin-bottom: 30px;" class="condition-content">
            <div class="dis-bet-cen condition-title">
              <span class="screening-conditions-title" style="font-size: 16px;color: #666666;">项目业绩</span>
              <span v-if="tags.achievement.length > 0" class="screening-conditions-title" style="font-size: 14px;color: #999999;">预计可提升{{ tags.achievement.length * 15 }}分，实际提升以审核为准。</span>
            </div>
            <el-row v-if="tags.achievement.length > 0" class="dis-cen-sta" style="margin-top: 10px;">
              <el-col :span="2"><div class="grid-content bg-purple font-choose-text">已上传资料：</div></el-col>
              <el-col :span="20">
                <div class="grid-content bg-purple dis-sta-cen" style="flex-wrap: wrap;">
                  <el-tag style="margin-right: 10px;margin-bottom: 10px;cursor:pointer;" v-for="(tag, index) in tags.achievement" :key="index" :closable="tag.submitState === '1' ? false : true" :type="getTagsType(tag.submitState)" @close="closeTag(tag, index)" @click="clickTag(tag, index)">
                    项目业绩：{{tag.projectName}}&nbsp;&nbsp;共上传了{{ (tag.fileList.length ? tag.fileList.length : 0) + (tag.invoiceFileList.length ? tag.invoiceFileList.length : 0) + (tag.partContractFileList.length ? tag.partContractFileList.length : 0) }}个文件 <span v-if="tag.submitState === '1'" class="icon iconfont">&#xe60e;</span>
                  </el-tag>
                </div>
              </el-col>
              <el-col :span="2">
                <div @click="closeAll('credential', true)" class="grid-content bg-purple font-choose-text">清除全部</div>
              </el-col>
            </el-row>
            <el-row class="dis-sta-sta magin-top-bottom">
              <el-col :span="24" style="">
                <el-button size="medium" class="uploadButton" type="primary" @click="achievementUpload">
                  <p style="font-size: 14px;color: #FFFFFF;"><i style="font-size: 12px;" class="iconfont">&#xe711;</i>添加</p>
                </el-button>
              </el-col>
            </el-row>
          </div>
          <!--知识产权证书-->
          <div style="margin-bottom: 30px;" class="condition-content">
            <div class="dis-bet-cen condition-title">
              <span class="screening-conditions-title" style="font-size: 16px;color: #666666;">知识产权证书</span>
              <span v-if="tags.intellectualProperty.length > 0" class="screening-conditions-title" style="font-size: 14px;color: #999999;">预计可提升{{ tags.intellectualProperty.length * 5 }}分，实际提升以审核为准。</span>
            </div>
            <el-row v-if="tags.intellectualProperty.length > 0" class="dis-cen-sta" style="margin-top: 10px;">
              <el-col :span="2"><div class="grid-content bg-purple font-choose-text">已上传资料：</div></el-col>
              <el-col :span="20">
                <div class="grid-content bg-purple dis-sta-cen" style="flex-wrap: wrap;">
                  <el-tag style="margin-right: 10px;margin-bottom: 10px;cursor:pointer;" v-for="(tag, index) in tags.intellectualProperty" :key="index" :closable="tag.submitState === '1' ? false : true" :type="getTagsType(tag.submitState)" @close="closeTag(tag, index)" @click="clickTag(tag, index)">
                    {{ getKnowledgeType(tag.knowledgeType) }}：{{ getKnowledgeName(tag) }}&nbsp;&nbsp;共上传了{{ tag.fileList.length ? tag.fileList.length : 0 }}个文件 <span v-if="tag.submitState === '1'" class="icon iconfont">&#xe60e;</span>
                  </el-tag>
                </div>
              </el-col>
              <el-col :span="2">
                <div @click="closeAll('credential', true)" class="grid-content bg-purple font-choose-text">清除全部</div>
              </el-col>
            </el-row>
            <el-row class="dis-sta-sta magin-top-bottom">
              <el-col :span="24" style="">
                <el-button size="medium" class="uploadButton" type="primary" @click="intellectualPropertyUpload">
                  <p style="font-size: 14px;color: #FFFFFF;"><i style="font-size: 12px;" class="iconfont">&#xe711;</i>添加</p>
                </el-button>
              </el-col>
            </el-row>
          </div>
          <!--税务荣誉证书-->
          <div style="margin-bottom: 30px;" class="condition-content">
            <div class="dis-bet-cen condition-title">
              <span class="screening-conditions-title" style="font-size: 16px;color: #666666;">税务荣誉证书</span>
              <span v-if="tags.taxcredit.length > 0" class="screening-conditions-title" style="font-size: 14px;color: #999999;">预计可提升{{ tags.taxcredit.length * 10 }}分，实际提升以审核为准。</span>
            </div>
            <el-row v-if="tags.taxcredit.length > 0" class="dis-cen-sta" style="margin-top: 10px;">
              <el-col :span="2"><div class="grid-content bg-purple font-choose-text">已上传资料：</div></el-col>
              <el-col :span="20">
                <div class="grid-content bg-purple dis-sta-cen" style="flex-wrap: wrap;">
                  <el-tag style="margin-right: 10px;margin-bottom: 10px;cursor:pointer;" v-for="(tag, index) in tags.taxcredit" :key="index" :closable="tag.submitState === '1' ? false : true" :type="getTagsType(tag.submitState)" @close="closeTag(tag, index)" @click="clickTag(tag, index)">
                    税务荣誉：{{tag.year}}>{{tag.level}}&nbsp;&nbsp;共上传了{{ tag.fileList.length ? tag.fileList.length : 0 }}个文件 <span v-if="tag.submitState === '1'" class="icon iconfont">&#xe60e;</span>
                  </el-tag>
                </div>
              </el-col>
              <el-col :span="2">
                <div @click="closeAll('credential', true)" class="grid-content bg-purple font-choose-text">清除全部</div>
              </el-col>
            </el-row>
            <el-row class="dis-sta-sta magin-top-bottom">
              <el-col :span="24" style="">
                <el-button size="medium" class="uploadButton" type="primary" @click="taxcreditUpload">
                  <p style="font-size: 14px;color: #FFFFFF;"><i style="font-size: 12px;" class="iconfont">&#xe711;</i>添加</p>
                </el-button>
              </el-col>
            </el-row>
          </div>
          <!--其他-->
          <div style="margin-bottom: 30px;" class="condition-content">
            <div class="dis-bet-cen condition-title">
              <span class="screening-conditions-title" style="font-size: 16px;color: #666666;">其他</span>
              <!--<span v-if="tags.other.length > 0" class="screening-conditions-title" style="font-size: 14px;color: #999999;">预计可提升{{ tags.other.length * 20 }}分，实际提升以审核为准。</span>-->
            </div>
            <el-row v-if="tags.other.length > 0" class="dis-cen-sta" style="margin-top: 10px;">
              <el-col :span="2"><div class="grid-content bg-purple font-choose-text">已上传资料：</div></el-col>
              <el-col :span="20">
                <div class="grid-content bg-purple dis-sta-cen" style="flex-wrap: wrap;">
                  <el-tag style="margin-right: 10px;margin-bottom: 10px;cursor:pointer;" v-for="(tag, index) in tags.other" :key="index" :closable="tag.submitState === '1' ? false : true" :type="getTagsType(tag.submitState)" @close="closeTag(tag, index)" @click="clickTag(tag, index)">
                    其他：{{tag.informationName}}&nbsp;&nbsp;共上传了{{ tag.fileList.length ? tag.fileList.length : 0 }}个文件 <span v-if="tag.submitState === '1'" class="icon iconfont">&#xe60e;</span>
                  </el-tag>
                </div>
              </el-col>
              <el-col :span="2">
                <div @click="closeAll('credential')" class="grid-content bg-purple font-choose-text">清除全部</div>
              </el-col>
            </el-row>
            <el-row class="dis-sta-sta magin-top-bottom">
              <el-col :span="24" style="">
                <el-button size="medium" class="uploadButton" type="primary" @click="otherUpload">
                  <p style="font-size: 14px;color: #FFFFFF;"><i style="font-size: 12px;" class="iconfont">&#xe711;</i>添加</p>
                </el-button>
              </el-col>
            </el-row>
          </div>
        </div>
      </el-row>
      <el-affix position="bottom" :offset="0">
        <el-row style="background: #FFFFFF;border-top: 1px solid #ebebeb;">
          <el-col :span="24">
            <div class="container dis-cen-cen" style="height: 68px;line-height: 68px;justify-content: flex-end;">
              <div>
                <p style="display: inline;font-size: 16px;color: #999999;">添加材料后，请点击上传审核，审核结果可在【个人中心】-<span class="link-class" @click="handleToWh">【维护记录】</span>查看，同时可关注</p>
                <!--publicUrl-->
                <el-tooltip placement="top">
                  <template #content>
                    <el-image
                      style="width: 100px; height: 100px"
                      :src="publicUrl"
                      :fit="contain"></el-image>
                  </template>
                  <p style="display: inline;font-size: 16px;color: #5D6FE9;">公众号-易工程平台</p>
                </el-tooltip>
                <p style="display: inline;font-size: 16px;color: #999999;">实时接收审核结果。</p>
              </div>
              <el-button style="width: 150px;" type="warning" :loading="ultimateSubmitLoading.credential || ultimateSubmitLoading.honorImage || ultimateSubmitLoading.registeredPersonnel || ultimateSubmitLoading.achievement || ultimateSubmitLoading.intellectualProperty || ultimateSubmitLoading.taxcredit || ultimateSubmitLoading.other" @click="ultimateSubmit">上传审核</el-button>
            </div>
          </el-col>
        </el-row>
      </el-affix>
      <el-dialog
        append-to-body
        :close-on-click-modal="false"
        title="上传资质证书"
        v-model="dialog.credential"
        width="40%"
        :before-close="handleClose">
        <el-form :model="credentialForm" :rules="credentialRules" ref="credentialForm" label-width="" label-position="right" style="margin-top: 0px" >
          <el-form-item label="资质名称：" prop="credentialName">
            <el-input placeholder="请输入资质名称" class="dis-sta-cen" style="width: 80%;" v-model="credentialForm.credentialName" ></el-input>
          </el-form-item>
          <el-form-item label="证书编号：" prop="credentialNumber">
            <el-input placeholder="请输入证书编号" class="dis-sta-cen" style="width: 80%;" v-model="credentialForm.credentialNumber" ></el-input>
          </el-form-item>
          <el-form-item label="资质等级：" prop="qualificationGrade">
            <el-input placeholder="请输入资质等级" class="dis-sta-cen" style="width: 80%;" v-model="credentialForm.qualificationGrade" ></el-input>
          </el-form-item>
          <el-form-item label="发证机构：" prop="issuedBy">
            <el-input placeholder="请输入发证机构" class="dis-sta-cen" style="width: 80%;" v-model="credentialForm.issuedBy" ></el-input>
          </el-form-item>
          <el-form-item label="有效日期：" prop="effectiveDate">
            <el-date-picker v-model="credentialForm.effectiveDate" style="width: 80%;" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
            <!--<el-input placeholder="请输入发证机构" class="dis-sta-cen" style="width: 440px;" v-model="credentialForm.issuedBy" ></el-input>-->
          </el-form-item>
          <el-form-item label="原件扫描件：" prop="fileUrl">
            <el-upload
              class="upload-demo"
              :before-upload="function (file) { return beforeAvatarUpload(file, 'credential')}"
              :on-preview="handlePreview"
              :on-remove="function (file, fileList) { return handleRemove(file, fileList, 'credential')}"
              multiple
              :limit="vipLevelChange === '00010002' ? 200 : 9"
              :on-exceed="handleExceed"
              :file-list="credentialForm.fileList"
            >
              <el-button size="small" @click="pdfList = []" type="primary">点击上传</el-button>
              <template #tip>
                <div v-if="vipLevelChange === '00010002'" class="dis-sta-cen" style="display: inline-block;margin-left: 11px;">
                  <div>
                    <p style="font-size: 12px;color: #666666;line-height: 21px;;margin-top: 1px;">支持上传jpg、png、bmp、pdf 等格式文件</p>
                  </div>
                </div>
                <div v-else class="dis-sta-cen" style="display: inline-block;margin-left: 11px;vertical-align: top;line-height: 1;">
                  <div style="font-size: 12px;color: #666666;line-height: 21px;;margin-top: 1px;">只能上传jpg、png文件,限9张</div>
                  <div>
                    <span style="font-size: 12px;color: #5D6FE9;cursor: pointer;" @click="handleToVip">立即开通VIP会员</span>
                    <span style="font-size: 12px;color: #666666;">享有更多上传特权</span>
                  </div>
                </div>
              </template>
            </el-upload>
          </el-form-item>
        </el-form>
        <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialog.credential = false">取 消</el-button>
          <el-button type="primary" @click="credentialSubmit">确 定</el-button>
        </span>
        </template>
      </el-dialog>
      <el-dialog
        append-to-body
        :close-on-click-modal="false"
        title="上传荣誉证书"
        v-model="dialog.honorImage"
        width="40%"
        :before-close="handleClose">
        <el-form :model="honorImageForm" :rules="honorImageRules" ref="honorImageForm" label-width="" label-position="right" style="margin-top: 0px" >
          <el-form-item label="荣誉名称：" prop="name">
            <el-input placeholder="请输入荣誉名称" class="dis-sta-cen" style="width: 80%;" v-model="honorImageForm.name" ></el-input>
          </el-form-item>
          <el-form-item label="发证日期：" prop="issueData">
            <el-date-picker v-model="honorImageForm.issueData" class="dis-sta-cen" style="width: 80%;" type="date" placeholder="请选择发证日期"></el-date-picker>
          </el-form-item>
          <el-form-item label="荣誉类别：" prop="honorType">
            <div class="dis-sta-cen" style="width: 80%;">
              <el-select v-model="honorImageForm.honorType" style="width: 100%;" placeholder="请选择荣誉类别">
                <el-option label="行业荣誉" value="1"></el-option>
                <el-option label="工商荣誉" value="2"></el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item label="发证机构：" prop="issuedBy">
            <el-input placeholder="请输入发证机构" class="dis-sta-cen" style="width: 80%;" v-model="honorImageForm.issuedBy" ></el-input>
          </el-form-item>
          <el-form-item label="原件扫描件：" prop="fileUrl">
            <el-upload
              class="upload-demo"
              :before-upload="function (file) { return beforeAvatarUpload(file, 'honorImage')}"
              :on-preview="handlePreview"
              :on-remove="function (file, fileList) { return handleRemove(file, fileList, 'honorImage')}"
              multiple
              :limit="vipLevelChange === '00010002' ? 200 : 9"
              :on-exceed="handleExceed"
              :file-list="honorImageForm.fileList"
            >
              <el-button size="small" @click="pdfList = []" type="primary">点击上传</el-button>
              <template #tip>
                <div v-if="vipLevelChange === '00010002'" class="dis-sta-cen" style="display: inline-block;margin-left: 11px;">
                  <div>
                    <p style="font-size: 12px;color: #666666;line-height: 21px;;margin-top: 1px;">支持上传jpg、png、bmp、pdf 等格式文件</p>
                  </div>
                </div>
                <div v-else class="dis-sta-cen" style="display: inline-block;margin-left: 11px;vertical-align: top;line-height: 1;">
                  <div style="font-size: 12px;color: #666666;line-height: 21px;;margin-top: 1px;">只能上传jpg、png文件,限9张</div>
                  <div>
                    <span style="font-size: 12px;color: #5D6FE9;cursor: pointer;" @click="handleToVip">立即开通VIP会员</span>
                    <span style="font-size: 12px;color: #666666;">享有更多上传特权</span>
                  </div>
                </div>
              </template>
            </el-upload>
          </el-form-item>
        </el-form>
        <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialog.honorImage = false">取 消</el-button>
          <el-button type="primary" @click="honorImageSubmit">确 定</el-button>
        </span>
        </template>
      </el-dialog>
      <el-dialog
        append-to-body
        :close-on-click-modal="false"
        title="上传技术人员"
        v-model="dialog.registeredPersonnel"
        width="40%"
        :before-close="handleClose">
        <el-form :model="registeredPersonnelForm" :rules="registeredPersonnelRules" ref="registeredPersonnelForm" label-width="" label-position="right" style="margin-top: 0px" >
          <el-form-item label="人员分类：" prop="searchStringId">
            <div class="dis-sta-cen" style="width: 80%;">
              <el-cascader
                ref="cascader"
                clearable
                collapse-tags
                separator=">"
                filterable="true"
                placeholder="请选择人员分类"
                style="width: 100%;"
                v-model="registeredPersonnelForm.searchStringId"
                size="medium"
                :options="cascaderOptions"
                :props="cascaderProps"
                @change="cascaderHandleChange"></el-cascader>
            </div>
          </el-form-item>
          <el-form-item
            v-if="registeredPersonnelForm.artisanValue === '注册建造师' ||
            registeredPersonnelForm.artisanValue === '注册建筑师' ||
            registeredPersonnelForm.artisanValue === '现场管理人员' ||
            registeredPersonnelForm.artisanValue === '三类人员' ||
            registeredPersonnelForm.artisanValue === '注册电气工程师' ||
            registeredPersonnelForm.artisanValue === '注册造价工程师' ||
            registeredPersonnelForm.artisanValue === '其他人员' ||
            registeredPersonnelForm.artisanValue === '注册结构工程师' ||
            registeredPersonnelForm.artisanValue === '注册土木工程师（岩土）' ||
            registeredPersonnelForm.artisanValue === '注册化工工程师' ||
            registeredPersonnelForm.artisanValue === '注册公用设备工程师' ||
            registeredPersonnelForm.artisanValue === '注册监理工程师'"
            label="人员名称：" prop="name">
            <el-input placeholder="请输入人员名称" class="dis-sta-cen" style="width: 80%;" v-model="registeredPersonnelForm.name" ></el-input>
          </el-form-item>
          <el-form-item
            v-if="registeredPersonnelForm.artisanValue === '其他人员'"
            label="证书名称：" prop="registeredType">
            <el-input placeholder="请输入证书名称" class="dis-sta-cen" style="width: 80%;" v-model="registeredPersonnelForm.registeredType" ></el-input>
          </el-form-item>
          <el-form-item
            v-if="registeredPersonnelForm.artisanValue === '注册建造师' ||
            registeredPersonnelForm.artisanValue === '注册建筑师' ||
            registeredPersonnelForm.artisanValue === '现场管理人员' ||
            registeredPersonnelForm.artisanValue === '三类人员' ||
            registeredPersonnelForm.artisanValue === '注册电气工程师' ||
            registeredPersonnelForm.artisanValue === '注册造价工程师' ||
            registeredPersonnelForm.artisanValue === '其他人员' ||
            registeredPersonnelForm.artisanValue === '注册结构工程师' ||
            registeredPersonnelForm.artisanValue === '注册土木工程师（岩土）' ||
            registeredPersonnelForm.artisanValue === '注册化工工程师' ||
            registeredPersonnelForm.artisanValue === '注册公用设备工程师' ||
            registeredPersonnelForm.artisanValue === '注册监理工程师'"
            label="执业印章号：" prop="certificateNo">
            <el-input placeholder="请输入执业印章号" class="dis-sta-cen" style="width: 80%;" v-model="registeredPersonnelForm.certificateNo" ></el-input>
          </el-form-item>
          <el-form-item
            v-if="registeredPersonnelForm.artisanValue === '注册建造师' ||
            registeredPersonnelForm.artisanValue === '注册建筑师' ||
            registeredPersonnelForm.artisanValue === '注册造价工程师' ||
            registeredPersonnelForm.artisanValue === '注册监理工程师'"
            label="注册专业：" prop="registeredProfessional">
            <el-input placeholder="请输入注册专业" class="dis-sta-cen" style="width: 80%;" v-model="registeredPersonnelForm.registeredProfessional" ></el-input>
          </el-form-item>
          <el-form-item
            v-if="registeredPersonnelForm.artisanValue === '注册结构工程师' ||
            registeredPersonnelForm.artisanValue === '注册土木工程师（岩土）' ||
            registeredPersonnelForm.artisanValue === '注册化工工程师' ||
            registeredPersonnelForm.artisanValue === '注册公用设备工程师' ||
            registeredPersonnelForm.artisanValue === '注册监理工程师'"
            label="有效日期：" prop="validTime">
            <el-date-picker v-model="registeredPersonnelForm.validTime" @change="validTimeChange" class="dis-sta-cen" style="width: 80%;" type="daterange"  range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间"> placeholder="请选择有效日期"></el-date-picker>
          </el-form-item>
          <el-form-item
            v-if="registeredPersonnelForm.artisanValue === '其他人员'"
            label="原件扫描件：" prop="fileUrl">
            <el-upload
              class="upload-demo"
              :before-upload="function (file) { return beforeAvatarUpload(file, 'registeredPersonnel')}"
              :on-preview="handlePreview"
              :on-remove="function (file, fileList) { return handleRemove(file, fileList, 'registeredPersonnel')}"
              multiple
              :limit="vipLevelChange === '00010002' ? 200 : 9"
              :on-exceed="handleExceed"
              :file-list="registeredPersonnelForm.fileList"
            >
              <el-button size="small" @click="pdfList = []" type="primary">点击上传</el-button>
              <template #tip>
                <div v-if="vipLevelChange === '00010002'" class="dis-sta-cen" style="display: inline-block;margin-left: 11px;">
                  <div>
                    <p style="font-size: 12px;color: #666666;line-height: 21px;;margin-top: 1px;">支持上传jpg、png、bmp、pdf 等格式文件</p>
                  </div>
                </div>
                <div v-else class="dis-sta-cen" style="display: inline-block;margin-left: 11px;vertical-align: top;line-height: 1;">
                  <div style="font-size: 12px;color: #666666;line-height: 21px;;margin-top: 1px;">只能上传jpg、png文件,限9张</div>
                  <div>
                    <span style="font-size: 12px;color: #5D6FE9;cursor: pointer;" @click="handleToVip">立即开通VIP会员</span>
                    <span style="font-size: 12px;color: #666666;">享有更多上传特权</span>
                  </div>
                </div>
              </template>
            </el-upload>
          </el-form-item>
        </el-form>
        <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialog.registeredPersonnel = false">取 消</el-button>
          <el-button type="primary" @click="registeredPersonnelSubmit">确 定</el-button>
        </span>
        </template>
      </el-dialog>
      <el-dialog
        append-to-body
        :close-on-click-modal="false"
        title="上传项目业绩"
        v-model="dialog.achievement"
        width="400"
        :before-close="handleClose">
        <el-form :model="achievementForm" :rules="achievementRules" ref="achievementForm" label-width="" label-position="right" style="margin-top: 0px" >
          <el-form-item label="项目承包：" prop="isSubcontract">
            <div class="dis-sta-cen" style="width: 80%;">
              <el-select v-model="achievementForm.isSubcontract" @change="isSubcontractChanage" style="width: 100%;" placeholder="请选择承包分类">
                <el-option label="总包" value="1"></el-option>
                <el-option label="分包" value="0"></el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item label="项目名称：" prop="projectName">
            <el-input placeholder="请输入项目名称" class="dis-sta-cen" style="width: 80%;" v-model="achievementForm.projectName" ></el-input>
          </el-form-item>
          <el-form-item label="合同总额：" prop="money">
            <el-input :maxlength="30" @input="achievementForm.money = achievementForm.money.match(/\d+(\.\d{0,4})?/) ? achievementForm.money.match(/\d+(\.\d{0,4})?/)[0] : ''" placeholder="请输入合同总额" class="dis-sta-cen" style="width: 80%;" v-model="achievementForm.money" ></el-input>
          </el-form-item>
          <el-form-item label="建设单位：" prop="projectConstructor">
            <el-input placeholder="请输入建设单位" class="dis-sta-cen" style="width: 80%;" v-model="achievementForm.projectConstructor" ></el-input>
          </el-form-item>
          <el-form-item label="中标日期：" prop="timeSuccess">
            <el-date-picker v-model="achievementForm.timeSuccess" class="dis-sta-cen" style="width: 80%;" type="date" placeholder="请选择中标日期"></el-date-picker>
          </el-form-item>
          <el-form-item v-if="achievementForm.isSubcontract === '0'" label="分包合同原件扫描件：" prop="partContractFileUrl">
            <el-upload
              class="upload-demo"
              :before-upload="function (file) { return beforeAvatarUpload(file, 'achievementForm=>partContractFileUrl')}"
              :on-preview="handlePreview"
              :on-remove="function (file, fileList) { return handleRemove(file, fileList, 'achievementForm=>partContractFileUrl')}"
              multiple
              :limit="vipLevelChange === '00010002' ? 200 : 9"
              :on-exceed="handleExceed"
              :file-list="achievementForm.partContractFileList"
            >
              <el-button size="small" @click="pdfList = []" type="primary">点击上传</el-button>
              <template #tip>
                <div v-if="vipLevelChange === '00010002'" class="dis-sta-cen" style="display: inline-block;margin-left: 11px;">
                  <div>
                    <p style="font-size: 12px;color: #666666;line-height: 21px;;margin-top: 1px;">支持上传jpg、png、bmp、pdf 等格式文件</p>
                  </div>
                </div>
                <div v-else class="dis-sta-cen" style="display: inline-block;margin-left: 11px;vertical-align: top;line-height: 1;">
                  <div style="font-size: 12px;color: #666666;line-height: 21px;;margin-top: 1px;">只能上传jpg、png文件,限9张</div>
                  <div>
                    <span style="font-size: 12px;color: #5D6FE9;cursor: pointer;" @click="handleToVip">立即开通VIP会员</span>
                    <span style="font-size: 12px;color: #666666;">享有更多上传特权</span>
                  </div>
                </div>
              </template>
            </el-upload>
          </el-form-item>
          <el-form-item v-if="achievementForm.isSubcontract === '0'" label="发票原件扫描件：" prop="invoiceFileUrl">
            <el-upload
              class="upload-demo"
              :before-upload="function (file) { return beforeAvatarUpload(file, 'achievementForm=>invoiceFileUrl')}"
              :on-preview="handlePreview"
              :on-remove="function (file, fileList) { return handleRemove(file, fileList, 'achievementForm=>invoiceFileUrl')}"
              multiple
              :limit="vipLevelChange === '00010002' ? 200 : 9"
              :on-exceed="handleExceed"
              :file-list="achievementForm.invoiceFileList"
            >
              <el-button size="small" @click="pdfList = []" type="primary">点击上传</el-button>
              <template #tip>
                <div v-if="vipLevelChange === '00010002'" class="dis-sta-cen" style="display: inline-block;margin-left: 11px;line-height: 1;vertical-align: top;">
                  <div>
                    <p style="font-size: 12px;color: #666666;line-height: 21px;">支持上传jpg、png、bmp、pdf 等格式文件</p>
                    <span style="font-size: 12px;color: #666666;line-height: 21px;">根据可核实的发票金额确定合同金额</span>
                  </div>
                </div>
                <div v-else class="dis-sta-cen" style="display: inline-block;margin-left: 11px;vertical-align: top;line-height: 1;">
                  <div style="font-size: 12px;color: #666666;line-height: 21px;;margin-top: 1px;">只能上传jpg、png文件,限9张,根据可核实的发票金额确定合同金额.</div>
                  <div>
                    <span style="font-size: 12px;color: #5D6FE9;cursor: pointer;" @click="handleToVip">立即开通VIP会员</span>
                    <span style="font-size: 12px;color: #666666;">享有更多上传特权</span>
                  </div>
                </div>
              </template>
            </el-upload>
          </el-form-item>
          <el-divider content-position="center"><p style="background-color: #FFFFFF;margin-left: 1px; margin-right: 1px">以下二选一填写</p></el-divider>
          <el-form-item label="中标链接：" prop="url">
            <el-input placeholder="请输入中标链接" class="dis-sta-cen" style="width: 80%;" v-model="achievementForm.url" @input="achievementFormUrlInput"></el-input>
          </el-form-item>
          <el-divider content-position="center"><p style="background-color: #FFFFFF;margin-left: 1px; margin-right: 1px">或</p></el-divider>
          <el-form-item v-if="achievementForm.isSubcontract === '1'" label="合同原件扫描件：" prop="fileUrl">
            <el-upload
              class="upload-demo"
              :before-upload="function (file) { return beforeAvatarUpload(file, 'achievementForm=>fileUrl')}"
              :on-preview="handlePreview"
              :on-remove="function (file, fileList) { return handleRemove(file, fileList, 'achievementForm=>fileUrl')}"
              multiple
              :limit="vipLevelChange === '00010002' ? 200 : 9"
              :on-exceed="handleExceed"
              :file-list="achievementForm.fileList"
            >
              <el-button size="small" @click="pdfList = []" type="primary">点击上传</el-button>
              <template #tip>
                <div v-if="vipLevelChange === '00010002'" class="dis-sta-cen" style="display: inline-block;margin-left: 11px;">
                  <div>
                    <p style="font-size: 12px;color: #666666;line-height: 21px;;margin-top: 1px;">支持上传jpg、png、bmp、pdf 等格式文件</p>
                  </div>
                </div>
                <div v-else class="dis-sta-cen" style="display: inline-block;margin-left: 11px;vertical-align: top;line-height: 1;">
                  <div style="font-size: 12px;color: #666666;line-height: 21px;;margin-top: 1px;">只能上传jpg、png文件,限9张</div>
                  <div>
                    <span style="font-size: 12px;color: #5D6FE9;cursor: pointer;" @click="handleToVip">立即开通VIP会员</span>
                    <span style="font-size: 12px;color: #666666;">享有更多上传特权</span>
                  </div>
                </div>
              </template>
            </el-upload>
          </el-form-item>
          <el-form-item v-if="achievementForm.isSubcontract === '1'" label="发票原件扫描件：" prop="invoiceFileUrl">
            <el-upload
              class="upload-demo"
              :before-upload="function (file) { return beforeAvatarUpload(file, 'achievementForm=>invoiceFileUrl')}"
              :on-preview="handlePreview"
              :on-remove="function (file, fileList) { return handleRemove(file, fileList, 'achievementForm=>invoiceFileUrl')}"
              multiple
              :limit="vipLevelChange === '00010002' ? 200 : 9"
              :on-exceed="handleExceed"
              :file-list="achievementForm.invoiceFileList"
            >
              <el-button size="small" @click="pdfList = []" type="primary">点击上传</el-button>
              <template #tip>
                <div v-if="vipLevelChange === '00010002'" class="dis-sta-cen" style="display: inline-block;margin-left: 11px;line-height: 1;vertical-align: top;">
                  <div>
                    <p style="font-size: 12px;color: #666666;line-height: 21px;">支持上传jpg、png、bmp、pdf 等格式文件</p>
                    <span style="font-size: 12px;color: #666666;line-height: 21px;">根据可核实的发票金额确定合同金额</span>
                  </div>
                </div>
                <div v-else class="dis-sta-cen" style="display: inline-block;margin-left: 11px;vertical-align: top;line-height: 1;">
                  <div style="font-size: 12px;color: #666666;line-height: 21px;;margin-top: 1px;">只能上传jpg、png文件,限9张，根据可核实的发票金额确定合同金额。</div>
                  <div>
                    <span style="font-size: 12px;color: #5D6FE9;cursor: pointer;" @click="handleToVip">立即开通VIP会员</span>
                    <span style="font-size: 12px;color: #666666;">享有更多上传特权</span>
                  </div>
                </div>
              </template>
            </el-upload>
          </el-form-item>
          <el-form-item v-if="achievementForm.isSubcontract === '0'" label="总包合同原件扫描件：" prop="fileUrl">
            <el-upload
              class="upload-demo"
              :before-upload="function (file) { return beforeAvatarUpload(file, 'achievementForm=>fileUrl')}"
              :on-preview="handlePreview"
              :on-remove="function (file, fileList) { return handleRemove(file, fileList, 'achievementForm=>fileUrl')}"
              multiple
              :limit="vipLevelChange === '00010002' ? 200 : 9"
              :on-exceed="handleExceed"
              :file-list="achievementForm.fileList"
            >
              <el-button size="small" @click="pdfList = []" type="primary">点击上传</el-button>
              <template #tip>
                <div v-if="vipLevelChange === '00010002'" class="dis-sta-cen" style="display: inline-block;margin-left: 11px;">
                  <div>
                    <p style="font-size: 12px;color: #666666;line-height: 21px;margin-top: 1px;">支持上传jpg、png、bmp、pdf 等格式文件</p>
                  </div>
                </div>
                <div v-else class="dis-sta-cen" style="display: inline-block;margin-left: 11px;vertical-align: top;line-height: 1;">
                  <div style="font-size: 12px;color: #666666;line-height: 21px;margin-top: 1px;">只能上传jpg、png文件,限9张</div>
                  <div>
                    <span style="font-size: 12px;color: #5D6FE9;cursor: pointer;" @click="handleToVip">立即开通VIP会员</span>
                    <span style="font-size: 12px;color: #666666;">享有更多上传特权</span>
                  </div>
                </div>
              </template>
            </el-upload>
          </el-form-item>
        </el-form>
        <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialog.achievement = false">取 消</el-button>
          <el-button type="primary" @click="achievementSubmit">确 定</el-button>
        </span>
        </template>
      </el-dialog>
      <el-dialog
        append-to-body
        :close-on-click-modal="false"
        title="上传知识产权证书"
        v-model="dialog.intellectualProperty"
        width="40%"
        :before-close="handleClose">
        <el-form :model="intellectualPropertyForm" :rules="intellectualPropertyRules" ref="intellectualPropertyForm" label-width="" label-position="right" style="margin-top: 0px" >
          <el-form-item label="产权分类：" prop="knowledgeType">
            <div class="dis-sta-cen" style="width: 80%;">
              <el-select v-model="intellectualPropertyForm.knowledgeType" @change="knowledgeTypeChange" style="width: 100%;" placeholder="请选择产权分类">
                <el-option label="专利信息" value="Patent"></el-option>
                <el-option label="软件著作权" value="SoftwareCopyright"></el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item v-if="intellectualPropertyForm.knowledgeType === 'Patent'" label="证书编号：" prop="certificateNumber">
            <el-input placeholder="请输入证书编号" class="dis-sta-cen" style="width: 80%;" v-model="intellectualPropertyForm.certificateNumber" ></el-input>
          </el-form-item>
          <el-form-item v-if="intellectualPropertyForm.knowledgeType === 'Patent'" label="专利名称：" prop="title">
            <el-input placeholder="请输入专利名称" class="dis-sta-cen" style="width: 80%;" v-model="intellectualPropertyForm.title" ></el-input>
          </el-form-item>
          <el-form-item v-if="intellectualPropertyForm.knowledgeType === 'Patent'" label="专利类别：" prop="kindcodedesc">
            <div class="dis-sta-cen" style="width: 80%;">
              <el-select v-model="intellectualPropertyForm.kindcodedesc" clearable style="width: 100%;" placeholder="请选择专利类别">
                <el-option v-for="(item, index) in knowledgeTypeList" :key="index" :label="item.label" :value="item.label"></el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item v-if="intellectualPropertyForm.knowledgeType === 'Patent'" label="发证日期：" prop="releaseDate">
            <el-date-picker v-model="intellectualPropertyForm.releaseDate" class="dis-sta-cen" style="width: 80%;" type="date" placeholder="请选择发证日期"></el-date-picker>
          </el-form-item>
          <el-form-item v-if="intellectualPropertyForm.knowledgeType === 'SoftwareCopyright'" label="分类号：" prop="category">
            <el-input placeholder="请输入分类号" class="dis-sta-cen" style="width: 80%;" v-model="intellectualPropertyForm.category" ></el-input>
          </el-form-item>
          <el-form-item v-if="intellectualPropertyForm.knowledgeType === 'SoftwareCopyright'" label="软件名称：" prop="name">
            <el-input placeholder="请输入软件名称" class="dis-sta-cen" style="width: 80%;" v-model="intellectualPropertyForm.name" ></el-input>
          </el-form-item>
          <el-form-item v-if="intellectualPropertyForm.knowledgeType === 'SoftwareCopyright'" label="简称：" prop="shortName">
            <el-input placeholder="请输入简称" class="dis-sta-cen" style="width: 80%;" v-model="intellectualPropertyForm.shortName" ></el-input>
          </el-form-item>
          <el-form-item v-if="intellectualPropertyForm.knowledgeType === 'SoftwareCopyright'" label="版本号：" prop="versionNo">
            <el-input placeholder="请输入版本号" class="dis-sta-cen" style="width: 80%;" v-model="intellectualPropertyForm.versionNo" ></el-input>
          </el-form-item>
          <el-form-item v-if="intellectualPropertyForm.knowledgeType === 'SoftwareCopyright'" label="登记号：" prop="registerNo">
            <el-input placeholder="请输入登记号" class="dis-sta-cen" style="width: 80%;" v-model="intellectualPropertyForm.registerNo" ></el-input>
          </el-form-item>
          <el-form-item v-if="intellectualPropertyForm.knowledgeType === 'SoftwareCopyright'" label="发布日期：" prop="publishDate">
            <el-date-picker v-model="intellectualPropertyForm.publishDate" class="dis-sta-cen" style="width: 80%;" type="date" placeholder="请选择发布日期"></el-date-picker>
          </el-form-item>
          <el-form-item v-if="intellectualPropertyForm.knowledgeType === 'SoftwareCopyright'" label="登记批准日期：" prop="registerAperDate">
            <el-date-picker v-model="intellectualPropertyForm.registerAperDate" class="dis-sta-cen" style="width: 80%;" type="date" placeholder="请选择登记批准日期"></el-date-picker>
          </el-form-item>
          <el-form-item label="原件扫描件：" prop="fileUrl">
            <el-upload
              class="upload-demo"
              :before-upload="function (file) { return beforeAvatarUpload(file, 'intellectualProperty')}"
              :on-preview="handlePreview"
              :on-remove="function (file, fileList) { return handleRemove(file, fileList, 'intellectualProperty')}"
              multiple
              :limit="vipLevelChange === '00010002' ? 200 : 9"
              :on-exceed="handleExceed"
              :file-list="intellectualPropertyForm.fileList"
            >
              <el-button size="small" @click="pdfList = []" type="primary">点击上传</el-button>
              <template #tip>
                <div v-if="vipLevelChange === '00010002'" class="dis-sta-cen" style="display: inline-block;margin-left: 11px;">
                  <div>
                    <p style="font-size: 12px;color: #666666;line-height: 21px;;margin-top: 1px;">支持上传jpg、png、bmp、pdf 等格式文件</p>
                  </div>
                </div>
                <div v-else class="dis-sta-cen" style="display: inline-block;margin-left: 11px;vertical-align: top;line-height: 1;">
                  <div style="font-size: 12px;color: #666666;line-height: 21px;;margin-top: 1px;">只能上传jpg、png文件,限9张</div>
                  <div>
                    <span style="font-size: 12px;color: #5D6FE9;cursor: pointer;" @click="handleToVip">立即开通VIP会员</span>
                    <span style="font-size: 12px;color: #666666;">享有更多上传特权</span>
                  </div>
                </div>
              </template>
            </el-upload>
          </el-form-item>
        </el-form>
        <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialog.intellectualProperty = false">取 消</el-button>
          <el-button type="primary" @click="intellectualPropertySubmit">确 定</el-button>
        </span>
        </template>
      </el-dialog>
      <el-dialog
        append-to-body
        :close-on-click-modal="false"
        title="上传税务荣誉证书"
        v-model="dialog.taxcredit"
        width="40%"
        :before-close="handleClose">
        <el-form :model="taxcreditForm" :rules="taxcreditRules" ref="taxcreditForm" label-width="" label-position="right" style="margin-top: 0px" >
          <el-form-item label="评价年度：" prop="year">
            <div class="dis-sta-cen" style="width: 80%;">
              <el-select v-model="taxcreditForm.year" clearable style="width: 100%;" placeholder="请选择评价年度">
                <el-option v-for="(item, index) in yearList" :key="index" :label="item.label" :value="item.label"></el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item label="纳税信用等级：" prop="level">
            <div class="dis-sta-cen" style="width: 80%;">
              <el-select v-model="taxcreditForm.level" clearable style="width: 100%;" placeholder="请选择纳税信用等级">
                <el-option v-for="(item, index) in levelList" :key="index" :label="item.label" :value="item.label"></el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item label="公告链接：" prop="announcementUrl">
            <el-input placeholder="请输入公告链接" class="dis-sta-cen" style="width: 80%;" v-model="taxcreditForm.announcementUrl" ></el-input>
          </el-form-item>
          <el-form-item label="原件扫描件：" prop="fileUrl">
            <el-upload
              class="upload-demo"
              :before-upload="function (file) { return beforeAvatarUpload(file, 'taxcredit')}"
              :on-preview="handlePreview"
              :on-remove="function (file, fileList) { return handleRemove(file, fileList, 'taxcredit')}"
              multiple
              :limit="vipLevelChange === '00010002' ? 200 : 9"
              :on-exceed="handleExceed"
              :file-list="taxcreditForm.fileList"
            >
              <el-button size="small" @click="pdfList = []" type="primary">点击上传</el-button>
              <template #tip>
                <div v-if="vipLevelChange === '00010002'" class="dis-sta-cen" style="display: inline-block;margin-left: 11px;">
                  <div>
                    <p style="font-size: 12px;color: #666666;line-height: 21px;;margin-top: 1px;">支持上传jpg、png、bmp、pdf 等格式文件,限9张</p>
                  </div>
                </div>
                <div v-else class="dis-sta-cen" style="display: inline-block;margin-left: 11px;vertical-align: top;line-height: 1;">
                  <div style="font-size: 12px;color: #666666;line-height: 21px;;margin-top: 1px;">只能上传jpg、png文件,限9张</div>
                  <div>
                    <span style="font-size: 12px;color: #5D6FE9;cursor: pointer;" @click="handleToVip">立即开通VIP会员</span>
                    <span style="font-size: 12px;color: #666666;">享有更多上传特权</span>
                  </div>
                </div>
              </template>
            </el-upload>
          </el-form-item>
        </el-form>
        <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialog.taxcredit = false">取 消</el-button>
          <el-button type="primary" @click="taxcreditSubmit">确 定</el-button>
        </span>
        </template>
      </el-dialog>
      <el-dialog
        append-to-body
        :close-on-click-modal="false"
        title="上传其他资料"
        v-model="dialog.other"
        width="40%"
        :before-close="handleClose">
        <el-form :model="otherForm" :rules="otherRules" ref="otherForm" label-width="" label-position="right" style="margin-top: 0px" >
          <el-form-item label="资料名称：" prop="informationName">
            <el-input placeholder="请输入资料名称" class="dis-sta-cen" style="width: 80%;" v-model="otherForm.informationName" ></el-input>
          </el-form-item>
          <el-form-item label="链接：" prop="url">
            <el-input placeholder="请输入链接" class="dis-sta-cen" style="width: 80%;" v-model="otherForm.url" @input="otherFormUrlInput" ></el-input>
          </el-form-item>
          <el-form-item label="备注：" prop="remark">
            <el-input placeholder="请输入备注" class="dis-sta-cen" style="width: 80%;" v-model="otherForm.remark" ></el-input>
          </el-form-item>
          <el-form-item label="原件扫描件：" prop="fileUrl">
            <el-upload
              class="upload-demo"
              :before-upload="function (file) { return beforeAvatarUpload(file, 'other')}"
              :on-preview="handlePreview"
              :on-remove="function (file, fileList) { return handleRemove(file, fileList, 'other')}"
              multiple
              :limit="vipLevelChange === '00010002' ? 200 : 9"
              :on-exceed="handleExceed"
              :file-list="otherForm.fileList"
            >
              <el-button size="small" @click="pdfList = []" type="primary">点击上传</el-button>
              <template #tip>
                <div v-if="vipLevelChange === '00010002'" class="dis-sta-cen" style="display: inline-block;margin-left: 11px;">
                  <div>
                    <p style="font-size: 12px;color: #666666;line-height: 21px;margin-top: 1px;">链接和附件两者中必填其一才能提交，</p>
                    <p style="font-size: 12px;color: #666666;line-height: 21px;margin-top: 1px;">支持上传jpg、png、bmp、pdf 等格式文件,限9张</p>
                  </div>
                </div>
                <div v-else class="dis-sta-cen" style="display: inline-block;margin-left: 11px;vertical-align: top;line-height: 1;">
                  <div style="font-size: 12px;color: #666666;line-height: 21px;margin-top: 1px;line-height: 1;">
                    <span style="font-size: 12px;color: #666666;line-height: 21px;margin-top: 1px;">链接和附件两者中必填其一才能提交，只能上传jpg、png文件,限9张</span>
                  </div>
                  <div>
                    <span style="font-size: 12px;color: #5D6FE9;cursor: pointer;" @click="handleToVip">立即开通VIP会员</span>
                    <span style="font-size: 12px;color: #666666;">享有更多上传特权</span>
                  </div>
                </div>
              </template>
            </el-upload>
          </el-form-item>
        </el-form>
        <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialog.other = false">取 消</el-button>
          <el-button type="primary" @click="otherSubmit">确 定</el-button>
        </span>
        </template>
      </el-dialog>
      <!-- 弹出 -->
      <el-dialog width="60%" append-to-body="true" v-model="dialogTableVisible">
        <el-row style="border-top: 1px solid #ebebeb;margin-bottom: 20px;padding-top: 20px;">
          <el-col :span="24" class="font-pay">开通VIP会员服务，平台审核周期为<span style="color: red;">1-2</span>个工作日</el-col>
          <el-col :span="24" class="font-pay">使用<span style="color: red;">手机浏览器</span>扫码获取更多特权，如有疑问请拨打客服电话：{{customerServiceTel || '400-8885449'}}</el-col>
        </el-row>
        <el-row style="margin: 20px 0;">
          <el-col :span="24" class="dis-cen-cen" style="background-color: #f5f5f9;padding: 30px 0;">
            <el-image fit="contain" style="width: 300px;margin-right: 50px;" draggable="false" :src="h5bg"></el-image>
          </el-col>
        </el-row>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { ElMessage, ElMessageBox } from 'element-plus'
import { mapGetters } from 'vuex'
import { getDictByModel } from '@/api/dict'
import {
  formTrim,
  parseTime,
  stringFre
} from '@/utils/index' // 工具类
import {
  forwardFile,
  // forwardBG,
  getArtisanRelevance,
  gloubeURl
} from '@/api/forward.js'
import { getAllArtisanRelevancy, forwardXmcic } from '@/api/query.js'
export default {
  name: 'data-maintain',
  data () {
    return {
      publicUrl: require('../../assets/index/g.png'), // 公众号二维码图
      ultimateSubmitLoading: {
        credential: false,
        honorImage: false,
        registeredPersonnel: false,
        achievement: false,
        intellectualProperty: false,
        taxcredit: false,
        other: false
      },
      h5bg: require('@/assets/images/h5.png'),
      // 标签已选条件
      tags: {
        credential: [],
        honorImage: [],
        registeredPersonnel: [],
        achievement: [],
        intellectualProperty: [],
        taxcredit: [],
        other: []
      },
      dialog: {
        credential: false,
        honorImage: false,
        registeredPersonnel: false,
        achievement: false,
        intellectualProperty: false,
        taxcredit: false,
        other: false
      },
      credentialForm: {
        companyName: '', // 公司名
        userId: '', // 用户id
        enterpriseId: '', // 公司id
        userName: '', // 用户名
        userMobile: '', // 手机号
        index: -1, // 在总体中的索引位置
        tagName: 'credential',
        priority: '', // 0 普通 1 vip 数字越大越牛逼
        submitState: '0', // 0:未提交 1提交成功 2提交失败
        // 以上进入获取 一下根据输入内容
        credentialName: '', // 资质名称
        credentialNumber: '', // 证书编号
        qualificationGrade: '', // 资质等级
        issuedBy: '', // 发证机构
        issueDate: '', // 有效时间开始
        closingDate: '', // 有效时间结束
        effectiveDate: '', // 有效时间
        fileUrl: '', // 文件地址 多个逗号分隔
        fileList: [] // [{name: '', url: ''}]
      },
      honorImageForm: {
        companyName: '', // 公司名
        userId: '', // 用户id
        enterpriseId: '', // 公司id
        userName: '', // 用户名
        userMobile: '', // 手机号
        index: -1, // 在总体中的索引位置
        tagName: 'honorImage',
        priority: '', // 0 普通 1 vip 数字越大越牛逼
        submitState: '0', // 0:未提交 1提交成功 2提交失败
        // 以上进入获取 一下根据输入内容
        name: '', // 荣誉名称
        issueData: '', // 发证时间
        honorType: '', // 荣誉类型 1:行业荣誉 2:工商荣誉
        issuedBy: '', // 发证单位
        fileUrl: '', // 图片
        fileList: [] // [{name: '', url: ''}]
      },
      registeredPersonnelForm: {
        companyName: '', // 公司名
        userId: '', // 用户id
        enterpriseId: '', // 公司id
        userName: '', // 用户名
        userMobile: '', // 手机号
        index: -1, // 在总体中的索引位置
        tagName: 'registeredPersonnel',
        priority: '', // 0 普通 1 vip 数字越大越牛逼
        submitState: '0', // 0:未提交 1提交成功 2提交失败
        // 以上进入获取 一下根据输入内容
        name: '', // 人员姓名
        registeredType: '', // 注册类别 人员下拉的最后一级
        level: '', // 只有外层的其他人员才填写其他人员 其他为空
        certificateNo: '', // 编号、注册号都叫执业印章号
        registeredTime: '', // 注册时间
        validPeriod: '', // 有效期限
        validTime: '', // 有效时间段 选择使用 选完分配给上面两个
        registeredProfessional: '', // 注册专业
        artisanValue: '', // 人员下拉的第一级
        searchString: '', // 人员下拉的第一级和第二集 注册建造师>一级注册建造师
        searchStringId: [], // 人员下拉的第一级和第二集 id>id
        fileUrl: '',
        fileList: []
      },
      achievementForm: {
        companyName: '', // 公司名
        userId: '', // 用户id
        enterpriseId: '', // 公司id
        userName: '', // 用户名
        userMobile: '', // 手机号
        index: -1, // 在总体中的索引位置
        tagName: 'achievement',
        priority: '', // 0 普通 1 vip 数字越大越牛逼
        submitState: '0', // 0:未提交 1提交成功 2提交失败
        // 以上进入获取 一下根据输入内容
        isSubcontract: '1', // 1:总包 0:分包
        projectName: '', // 项目名称
        money: '', // 合同金额
        projectConstructor: '', // 建设单位
        timeSuccess: '', // 中标日期 2021-07-01
        url: '', // 中标链接
        fileUrl: '', // 合同原件扫描件
        invoiceFileUrl: '', // 发票原件扫描件
        partContractFileUrl: '', // 分包合同原件扫描件
        fileList: [],
        invoiceFileList: [],
        partContractFileList: []
      },
      intellectualPropertyForm: {
        companyName: '', // 公司名
        userId: '', // 用户id
        enterpriseId: '', // 公司id
        userName: '', // 用户名
        userMobile: '', // 手机号
        index: -1, // 在总体中的索引位置
        tagName: 'intellectualProperty',
        priority: '', // 0 普通 1 vip 数字越大越牛逼
        submitState: '0', // 0:未提交 1提交成功 2提交失败
        // 以上进入获取 一下根据输入内容
        knowledgeType: 'Patent', // 软著:SoftwareCopyright 专利:Patent
        category: '', // 分类号
        name: '', // 软件名称
        shortName: '', // 简称
        versionNo: '', // 版本号
        registerNo: '', // 登记号
        publishDate: '', // 2021-07-01 发布日期
        registerAperDate: '', // 2021-07-01 登记批准日期
        // 一下专利使用
        certificateNumber: '', // 证书编号
        title: '', // 产权名称
        kindcodedesc: '', // PCT发明
        releaseDate: '', // 2021-07-01 发证日期
        fileUrl: '',
        fileList: []
      },
      taxcreditForm: {
        companyName: '', // 公司名
        userId: '', // 用户id
        enterpriseId: '', // 公司id
        userName: '', // 用户名
        userMobile: '', // 手机号
        index: -1, // 在总体中的索引位置
        tagName: 'taxcredit',
        priority: '', // 0 普通 1 vip 数字越大越牛逼
        submitState: '0', // 0:未提交 1提交成功 2提交失败
        // 以上进入获取 一下根据输入内容
        year: '', // 评价年度
        level: '', // 纳税信用等级
        announcementUrl: '', // 公告连接
        fileUrl: '',
        fileList: []
      },
      otherForm: {
        companyName: '', // 公司名
        userId: '', // 用户id
        enterpriseId: '', // 公司id
        userName: '', // 用户名
        userMobile: '', // 手机号
        index: -1, // 在总体中的索引位置
        tagName: 'other',
        priority: '', // 0 普通 1 vip 数字越大越牛逼
        submitState: '0', // 0:未提交 1提交成功 2提交失败
        // 以上进入获取 一下根据输入内容
        informationName: '', // 资料名称
        url: '', // 链接
        remark: '', // 备注
        fileUrl: '',
        fileList: []
      },
      credentialRules: {
        credentialName: [
          { required: true, message: '请输入资质名称', trigger: 'change' },
          { min: 1, max: 50, message: '长度在 1 到 50 个字符', trigger: 'change' }
        ],
        credentialNumber: [
          { required: true, message: '请输入证书编号', trigger: 'change' },
          { min: 1, max: 50, message: '长度在 1 到 50 个字符', trigger: 'change' }
        ],
        qualificationGrade: [
          { required: false, message: '请输入资质等级', trigger: 'change' },
          { min: 1, max: 50, message: '长度在 1 到 50 个字符', trigger: 'change' }
        ],
        issuedBy: [
          { required: false, message: '请输入发证机构', trigger: 'change' },
          { min: 1, max: 50, message: '长度在 1 到 50 个字符', trigger: 'change' }
        ],
        effectiveDate: [
          { required: false, message: '请选择有效时间', trigger: 'change' }
        ],
        fileUrl: [
          { required: true, message: '请上传原件扫描件', trigger: 'change' }
        ]
      },
      honorImageRules: {
        name: [
          { required: true, message: '请输入荣誉名称', trigger: 'change' },
          { min: 1, max: 50, message: '长度在 1 到 50 个字符', trigger: 'change' }
        ],
        issueData: [
          { required: true, message: '请选择发证日期', trigger: 'change' }
        ],
        honorType: [
          { required: true, message: '请选择荣誉类型', trigger: 'change' }
        ],
        issuedBy: [
          { required: false, message: '请输入发证单位', trigger: 'change' },
          { min: 1, max: 50, message: '长度在 1 到 50 个字符', trigger: 'change' }
        ],
        fileUrl: [
          { required: true, message: '请上传原件扫描件', trigger: 'change' }
        ]
      },
      registeredPersonnelRules: {
        name: [
          { required: true, message: '请输入人员名称', trigger: 'change' },
          { min: 1, max: 50, message: '长度在 1 到 50 个字符', trigger: 'change' }
        ],
        certificateNo: [
          { required: true, message: '请输入执业印章号', trigger: 'change' },
          { min: 1, max: 50, message: '长度在 1 到 50 个字符', trigger: 'change' }
        ],
        validTime: [
          { required: true, message: '请选择有效日期', trigger: 'change' }
        ],
        registeredProfessional: [
          { required: true, message: '请输入注册专业', trigger: 'change' },
          { min: 1, max: 50, message: '长度在 1 到 50 个字符', trigger: 'change' }
        ],
        registeredType: [
          { required: true, message: '请输入证书名称', trigger: 'change' },
          { min: 1, max: 50, message: '长度在 1 到 50 个字符', trigger: 'change' }
        ],
        searchStringId: [
          { required: true, message: '请选择人员分类', trigger: 'change' }
        ],
        fileUrl: [
          { required: true, message: '请上传原件扫描件', trigger: 'change' }
        ]
      },
      achievementRules: {
        isSubcontract: [
          { required: true, message: '请选择承包分类', trigger: 'change' }
        ],
        projectName: [
          { required: true, message: '请输入项目名称', trigger: 'change' },
          { min: 1, max: 50, message: '长度在 1 到 50 个字符', trigger: 'change' }
        ],
        money: [
          { required: true, message: '请输入合同金额', trigger: 'change' },
          {
            required: true,
            validator: (rule, value, callback) => {
              if (value !== '') {
                if (value * 1 <= 0) {
                  callback(new Error('合同金额必须大于0'))
                } else {
                  callback()
                }
              } else {
                callback()
              }
            },
            trigger: 'change'
          }
        ],
        projectConstructor: [
          { required: true, message: '请输入建设单位', trigger: 'change' },
          { min: 1, max: 50, message: '长度在 1 到 50 个字符', trigger: 'change' }
        ],
        timeSuccess: [
          { required: true, message: '请选择中标日期', trigger: 'change' }
        ],
        url: [
          { required: true, message: '请输入中标链接', trigger: 'change' }
        ],
        fileUrl: [
          { required: true, message: '请上传总包合同原件扫描件', trigger: 'change' }
        ],
        partContractFileUrl: [
          { required: true, message: '请上传分包合同原件扫描件', trigger: 'change' }
        ],
        invoiceFileUrl: [
          { required: true, message: '请上传发票原件扫描件', trigger: 'change' }
        ]
      },
      intellectualPropertyRules: {
        knowledgeType: [
          { required: true, message: '请选择产权分类', trigger: 'change' }
        ],
        category: [
          { required: false, message: '请输入分类号', trigger: 'change' },
          { min: 1, max: 50, message: '长度在 1 到 50 个字符', trigger: 'change' }
        ],
        name: [
          { required: true, message: '请输入软件名称', trigger: 'change' },
          { min: 1, max: 50, message: '长度在 1 到 50 个字符', trigger: 'change' }
        ],
        shortName: [
          { required: false, message: '请输入软件简称', trigger: 'change' },
          { min: 1, max: 50, message: '长度在 1 到 50 个字符', trigger: 'change' }
        ],
        versionNo: [
          { required: false, message: '请输入版本号', trigger: 'change' },
          { min: 1, max: 50, message: '长度在 1 到 50 个字符', trigger: 'change' }
        ],
        registerNo: [
          { required: true, message: '请输入登记号', trigger: 'change' },
          { min: 1, max: 50, message: '长度在 1 到 50 个字符', trigger: 'change' }
        ],
        publishDate: [
          { required: false, message: '请选择发布日期', trigger: 'change' }
        ],
        registerAperDate: [
          { required: true, message: '请选择登记批准日期', trigger: 'change' }
        ],
        certificateNumber: [
          { required: true, message: '请输入证书编号', trigger: 'change' },
          { min: 1, max: 50, message: '长度在 1 到 50 个字符', trigger: 'change' }
        ],
        title: [
          { required: true, message: '请输入专利名称', trigger: 'change' },
          { min: 1, max: 50, message: '长度在 1 到 50 个字符', trigger: 'change' }
        ],
        kindcodedesc: [
          { required: false, message: '请选择专利类别', trigger: 'change' }
        ],
        releaseDate: [
          { required: false, message: '请选择发证日期', trigger: 'change' }
        ],
        fileUrl: [
          { required: true, message: '请上传原件扫描件', trigger: 'change' }
        ]
      },
      taxcreditRules: {
        year: [
          { required: true, message: '请选择评价年度', trigger: 'change' }
        ],
        level: [
          { required: true, message: '请选择纳税信用等级', trigger: 'change' }
        ],
        announcementUrl: [
          { required: false, message: '请输入公告链接', trigger: 'change' }
        ],
        fileUrl: [
          { required: true, message: '请上传原件扫描件', trigger: 'change' }
        ]
      },
      otherRules: {
        informationName: [
          { required: true, message: '请输入资料名称', trigger: 'change' },
          { min: 1, max: 50, message: '长度在 1 到 50 个字符', trigger: 'change' }
        ],
        url: [
          { required: true, message: '请输入链接', trigger: 'change' }
        ],
        remark: [
          { required: false, message: '请输入备注', trigger: 'change' }
        ],
        fileUrl: [
          { required: true, message: '请上传资料附件', trigger: 'change' }
        ]
      },
      loading: '',
      knowledgeTypeList: [],
      yearList: [],
      levelList: [],
      cascaderOptions: [], // 需要的格式参考饿了么UI 的级联选择器
      cascaderProps: { multiple: false, expandTrigger: 'hover' },
      submitSuccessNum: 0,
      submitErrorNum: 0,
      pdfList: [],
      dialogTableVisible: false,
      cityName: ''
    }
  },
  computed: {
    ...mapGetters([
      'idToken',
      'userInfo',
      'vipData',
      'dataMaintainModel'
    ]),
    vipLevelChange: function () {
      const vm = this
      if (vm.vipData && JSON.parse(vm.vipData)) {
        return JSON.parse(vm.vipData).vipLevel
      } else {
        return null
      }
    }
  },
  mounted () {
    const vm = this
    vm.getCity()
    vm.initUserInfo()
    vm.initDict()
    vm.initYearList()
    vm.initArrangementRadioValueArr()
    vm.initDataMaintainModel()
  },
  methods: {
    getCity () {
      const vm = this
      this.userInfoMes = JSON.parse(this.userInfo)
      if (this.userInfoMes.enterpriseName && this.userInfoMes.businessInformationStatus === '1') {
        const params = {
          keyword: this.userInfoMes.enterpriseName.trim()
        }
        forwardXmcic(params).then(result => {
          if (result.code === 0) {
            vm.cityName = result.data
            if (result.data === '福建省') {
              ElMessageBox.confirm('该地区暂不开放数据维护功能！')
                .then(() => {
                  vm.$router.push({ name: 'data-correction' })
                })
                .catch(() => {
                  vm.$router.push({ name: 'data-correction' })
                })
            }
          } else {
            vm.$Message.error('获取失败，稍后重试！')
            vm.$router.push({ name: 'data-correction' })
          }
        })
      } else {
        this.$router.push({ name: 'data-correction' })
      }
    },
    handleToWh () {
      this.$router.push({ name: 'data-maintain-person' })
    },
    // 去开通vip
    handleToVip () {
      const vm = this
      vm.dialogTableVisible = true
      // const routeData = vm.$router.resolve({
      //   name: 'vip-index'
      // })
      // window.open(routeData.href, '_blank')
    },
    // 打开操作手册
    handleOpenPDF () {
      const url = 'https://img.yijingjia.com/1648097582474_20220324-PC.pdf'
      window.open(url, '_black')
    },
    // 获取所有人员分类
    initArrangementRadioValueArr () {
      const vm = this
      getAllArtisanRelevancy().then(result => {
        if (result.code === 0) {
          const res = result.data
          vm.getRelevancyList(res, '0', function (a) {
            const arr = []
            for (let i = 0; i < a.length; i++) {
              if (a[i].value !== '82b1e54c9b59404e991929181edc06df' && // 系统集成项目经理
                a[i].value !== '7b677b36b2194616b59717bce0e48245' && // 安全评价师
                a[i].value !== '07c9010f584d4124b61f443d17921529' && // 信息技术服务标准（ITSS）人员
                a[i].value !== '91aca79caf3c4dd4bfdffc11ad5d6ec4') { // 职称人员
                arr.push(a[i])
              }
            }
            vm.cascaderOptions = arr
          })
        }
      }).catch(error => {
        console.log(error)
      })
    },
    initYearList () {
      const vm = this
      const newYear = parseTime(new Date(), '{y}') * 1
      for (let i = newYear; i > newYear - 20; i--) {
        vm.yearList.push({
          label: i.toString(), // 显示的值
          value: i.toString() // 实际的值
        })
      }
    },
    initDict () {
      const vm = this
      const data = {
        dictCodeLike: '0018'
      }
      getDictByModel(data).then(result => {
        if (result.code === 0) {
          const data = result.data ? result.data : []
          for (let i = 0; i < data.length; i++) {
            if (data[i].dictNo !== '0018') {
              vm.knowledgeTypeList.push({
                label: data[i].dictName, // 显示的值
                value: data[i].dictNo // 实际的值
              })
            }
          }
        } else {
          return ElMessage.error(result.msg)
        }
      }).catch(error => {
        console.log(error)
      })
      const dataTwo = {
        dictCodeLike: '0019'
      }
      getDictByModel(dataTwo).then(result => {
        if (result.code === 0) {
          const data = result.data ? result.data : []
          for (let i = 0; i < data.length; i++) {
            if (data[i].dictNo !== '0019') {
              vm.levelList.push({
                label: data[i].dictName, // 显示的值
                value: data[i].dictNo // 实际的值
              })
            }
          }
        } else {
          return ElMessage.error(result.msg)
        }
      }).catch(error => {
        console.log(error)
      })
    },
    initUserInfo () {
      const vm = this
      const thisUserInfo = JSON.parse(this.userInfo)
      // 初始化资质条件
      vm.credentialForm.companyName = thisUserInfo.enterpriseName // 公司名
      vm.credentialForm.userId = thisUserInfo.userId // 用户id
      vm.credentialForm.enterpriseId = thisUserInfo.companyId // 公司id
      vm.credentialForm.userName = thisUserInfo.loginName // 用户名
      vm.credentialForm.userMobile = thisUserInfo.mobile // 手机号
      vm.credentialForm.priority = vm.vipLevelChange === '00010002' ? 1 : 0
      // 初始化荣誉条件
      vm.honorImageForm.companyName = thisUserInfo.enterpriseName // 公司名
      vm.honorImageForm.userId = thisUserInfo.userId // 用户id
      vm.honorImageForm.enterpriseId = thisUserInfo.companyId // 公司id
      vm.honorImageForm.userName = thisUserInfo.loginName // 用户名
      vm.honorImageForm.userMobile = thisUserInfo.mobile // 手机号
      vm.honorImageForm.priority = vm.vipLevelChange === '00010002' ? 1 : 0
      // 初始化人员条件
      vm.registeredPersonnelForm.companyName = thisUserInfo.enterpriseName // 公司名
      vm.registeredPersonnelForm.userId = thisUserInfo.userId // 用户id
      vm.registeredPersonnelForm.enterpriseId = thisUserInfo.companyId // 公司id
      vm.registeredPersonnelForm.userName = thisUserInfo.loginName // 用户名
      vm.registeredPersonnelForm.userMobile = thisUserInfo.mobile // 手机号
      vm.registeredPersonnelForm.priority = vm.vipLevelChange === '00010002' ? 1 : 0
      // 初始化中标条件
      vm.achievementForm.companyName = thisUserInfo.enterpriseName // 公司名
      vm.achievementForm.userId = thisUserInfo.userId // 用户id
      vm.achievementForm.enterpriseId = thisUserInfo.companyId // 公司id
      vm.achievementForm.userName = thisUserInfo.loginName // 用户名
      vm.achievementForm.userMobile = thisUserInfo.mobile // 手机号
      vm.achievementForm.priority = vm.vipLevelChange === '00010002' ? 1 : 0
      // 初始化知识产权条件
      vm.intellectualPropertyForm.companyName = thisUserInfo.enterpriseName // 公司名
      vm.intellectualPropertyForm.userId = thisUserInfo.userId // 用户id
      vm.intellectualPropertyForm.enterpriseId = thisUserInfo.companyId // 公司id
      vm.intellectualPropertyForm.userName = thisUserInfo.loginName // 用户名
      vm.intellectualPropertyForm.userMobile = thisUserInfo.mobile // 手机号
      vm.intellectualPropertyForm.priority = vm.vipLevelChange === '00010002' ? 1 : 0
      // 初始化税务荣誉条件
      vm.taxcreditForm.companyName = thisUserInfo.enterpriseName // 公司名
      vm.taxcreditForm.userId = thisUserInfo.userId // 用户id
      vm.taxcreditForm.enterpriseId = thisUserInfo.companyId // 公司id
      vm.taxcreditForm.userName = thisUserInfo.loginName // 用户名
      vm.taxcreditForm.userMobile = thisUserInfo.mobile // 手机号
      vm.taxcreditForm.priority = vm.vipLevelChange === '00010002' ? 1 : 0
      // 初始化其他条件
      vm.otherForm.companyName = thisUserInfo.enterpriseName // 公司名
      vm.otherForm.userId = thisUserInfo.userId // 用户id
      vm.otherForm.enterpriseId = thisUserInfo.companyId // 公司id
      vm.otherForm.userName = thisUserInfo.loginName // 用户名
      vm.otherForm.userMobile = thisUserInfo.mobile // 手机号
      vm.otherForm.priority = vm.vipLevelChange === '00010002' ? 1 : 0
    },
    // 资质点击了上传
    credentialUpload () {
      const vm = this
      vm.pdfList = []
      if (vm.$refs.credentialForm) vm.$refs.credentialForm.resetFields()
      vm.initCredentialForm()
      vm.dialog.credential = true
    },
    // 荣誉点击了上传
    honorImageUpload () {
      const vm = this
      vm.pdfList = []
      if (vm.$refs.honorImageForm) vm.$refs.honorImageForm.resetFields()
      vm.initHonorImageForm()
      vm.dialog.honorImage = true
    },
    // 人员点击了上传
    registeredPersonnelUpload () {
      const vm = this
      vm.pdfList = []
      if (vm.$refs.registeredPersonnelForm) vm.$refs.registeredPersonnelForm.resetFields()
      const searchStringId = ['1e3780295e2f44f0aae295ef464c129d', '6769342bf3e347fca5173fb9fde3ed9f']
      const artisanValue = '注册建造师'
      const registeredType = '一级注册建造师'
      const searchString = '注册建造师>一级注册建造师'
      const level = ''
      vm.initRegisteredPersonnelForm(searchStringId, artisanValue, registeredType, searchString, level)
      vm.dialog.registeredPersonnel = true
    },
    // 中标点击了上传
    achievementUpload () {
      const vm = this
      vm.pdfList = []
      if (vm.$refs.achievementForm) vm.$refs.achievementForm.resetFields()
      vm.initAchievementForm('1')
      vm.dialog.achievement = true
    },
    // 知识产权点击了上传
    intellectualPropertyUpload () {
      const vm = this
      vm.pdfList = []
      if (vm.$refs.intellectualPropertyForm) vm.$refs.intellectualPropertyForm.resetFields()
      vm.initIntellectualPropertyForm('Patent')
      vm.dialog.intellectualProperty = true
    },
    // 税务荣誉点击了上传
    taxcreditUpload () {
      const vm = this
      vm.pdfList = []
      if (vm.$refs.taxcreditForm) vm.$refs.taxcreditForm.resetFields()
      vm.initTaxcreditForm()
      vm.dialog.taxcredit = true
    },
    // 其他点击了上传
    otherUpload () {
      const vm = this
      vm.pdfList = []
      if (vm.$refs.otherForm) vm.$refs.otherForm.resetFields()
      vm.initOtherForm()
      vm.dialog.other = true
    },
    // 资质条件点击了确定
    credentialSubmit () {
      const vm = this
      vm.credentialForm = formTrim(vm.credentialForm)
      vm.$refs.credentialForm.validate((valid) => {
        if (valid) {
          if (vm.credentialForm.effectiveDate && vm.credentialForm.effectiveDate.length === 2) {
            vm.credentialForm.issueDate = parseTime(vm.credentialForm.effectiveDate[0], '{y}-{m}-{d}')
            vm.credentialForm.closingDate = parseTime(vm.credentialForm.effectiveDate[1], '{y}-{m}-{d}')
          }
          const index = vm.credentialForm.index
          vm.credentialForm.submitState = 0
          if (index >= 0) {
            // 修改
            vm.credentialForm.index = -1
            vm.tags.credential[index] = JSON.parse(JSON.stringify(vm.credentialForm))
          } else {
            // 加入tab中
            vm.tags.credential.push(JSON.parse(JSON.stringify(vm.credentialForm)))
          }
          vm.dialog.credential = false
        }
      })
    },
    // 荣誉条件点击了确定
    honorImageSubmit () {
      const vm = this
      vm.honorImageForm = formTrim(vm.honorImageForm)
      vm.$refs.honorImageForm.validate((valid) => {
        if (valid) {
          if (vm.honorImageForm.issueData) {
            vm.honorImageForm.issueData = parseTime(vm.honorImageForm.issueData, '{y}-{m}-{d}')
          }
          const index = vm.honorImageForm.index
          vm.honorImageForm.submitState = 0
          if (index >= 0) {
            // 修改
            vm.honorImageForm.index = -1
            vm.tags.honorImage[index] = JSON.parse(JSON.stringify(vm.honorImageForm))
          } else {
            // 加入tab中
            vm.tags.honorImage.push(JSON.parse(JSON.stringify(vm.honorImageForm)))
          }
          vm.dialog.honorImage = false
        }
      })
    },
    // 人员条件点击了确定
    registeredPersonnelSubmit () {
      const vm = this
      vm.registeredPersonnelForm = formTrim(vm.registeredPersonnelForm)
      vm.$refs.registeredPersonnelForm.validate((valid) => {
        if (valid) {
          const index = vm.registeredPersonnelForm.index
          vm.registeredPersonnelForm.submitState = 0
          if (index >= 0) {
            // 修改
            vm.registeredPersonnelForm.index = -1
            vm.tags.registeredPersonnel[index] = JSON.parse(JSON.stringify(vm.registeredPersonnelForm))
          } else {
            // 加入tab中
            vm.tags.registeredPersonnel.push(JSON.parse(JSON.stringify(vm.registeredPersonnelForm)))
          }
          vm.dialog.registeredPersonnel = false
        }
      })
    },
    // 中标条件点击了确定
    achievementSubmit () {
      const vm = this
      vm.achievementForm = formTrim(vm.achievementForm)
      vm.$refs.achievementForm.validate((valid) => {
        if (valid) {
          if (vm.achievementForm.timeSuccess) {
            vm.achievementForm.timeSuccess = parseTime(vm.achievementForm.timeSuccess, '{y}-{m}-{d}')
          }
          const index = vm.achievementForm.index
          vm.achievementForm.submitState = 0
          if (index >= 0) {
            // 修改
            vm.achievementForm.index = -1
            vm.tags.achievement[index] = JSON.parse(JSON.stringify(vm.achievementForm))
          } else {
            // 加入tab中
            vm.tags.achievement.push(JSON.parse(JSON.stringify(vm.achievementForm)))
          }
          vm.dialog.achievement = false
        }
      })
    },
    // 知识产权点击了确定
    intellectualPropertySubmit () {
      const vm = this
      vm.intellectualPropertyForm = formTrim(vm.intellectualPropertyForm)
      vm.$refs.intellectualPropertyForm.validate((valid) => {
        if (valid) {
          if (vm.intellectualPropertyForm.publishDate) {
            vm.intellectualPropertyForm.publishDate = parseTime(vm.intellectualPropertyForm.publishDate, '{y}-{m}-{d}')
          }
          if (vm.intellectualPropertyForm.registerAperDate) {
            vm.intellectualPropertyForm.registerAperDate = parseTime(vm.intellectualPropertyForm.registerAperDate, '{y}-{m}-{d}')
          }
          if (vm.intellectualPropertyForm.releaseDate) {
            vm.intellectualPropertyForm.releaseDate = parseTime(vm.intellectualPropertyForm.releaseDate, '{y}-{m}-{d}')
          }
          const index = vm.intellectualPropertyForm.index
          vm.intellectualPropertyForm.submitState = 0
          if (index >= 0) {
            // 修改
            vm.intellectualPropertyForm.index = -1
            vm.tags.intellectualProperty[index] = JSON.parse(JSON.stringify(vm.intellectualPropertyForm))
          } else {
            // 加入tab中
            vm.tags.intellectualProperty.push(JSON.parse(JSON.stringify(vm.intellectualPropertyForm)))
          }
          vm.dialog.intellectualProperty = false
        }
      })
    },
    // 税务荣誉点击了确定
    taxcreditSubmit () {
      const vm = this
      vm.taxcreditForm = formTrim(vm.taxcreditForm)
      vm.$refs.taxcreditForm.validate((valid) => {
        if (valid) {
          const index = vm.taxcreditForm.index
          vm.taxcreditForm.submitState = 0
          if (index >= 0) {
            // 修改
            vm.taxcreditForm.index = -1
            vm.tags.taxcredit[index] = JSON.parse(JSON.stringify(vm.taxcreditForm))
          } else {
            // 加入tab中
            vm.tags.taxcredit.push(JSON.parse(JSON.stringify(vm.taxcreditForm)))
          }
          vm.dialog.taxcredit = false
        }
      })
    },
    // 其他点击了确定
    otherSubmit () {
      const vm = this
      vm.otherForm = formTrim(vm.otherForm)
      vm.$refs.otherForm.validate((valid) => {
        if (valid) {
          const index = vm.otherForm.index
          vm.otherForm.submitState = 0
          if (index >= 0) {
            // 修改
            vm.otherForm.index = -1
            vm.tags.other[index] = JSON.parse(JSON.stringify(vm.otherForm))
          } else {
            // 加入tab中
            vm.tags.other.push(JSON.parse(JSON.stringify(vm.otherForm)))
          }
          vm.dialog.other = false
        }
      })
    },
    beforeAvatarUpload (file, obj) {
      const vm = this
      const pdfMaxNum = 10
      // 提示内容
      let fileManage = ''
      if (obj === 'credential') fileManage = '原件扫描件'
      if (obj === 'honorImage') fileManage = '原件扫描件'
      if (obj === 'registeredPersonnel') fileManage = '原件扫描件'
      if (obj === 'achievementForm=>partContractFileUrl') fileManage = '分包合同原件扫描件'
      if (obj === 'achievementForm=>invoiceFileUrl') fileManage = '发票原件扫描件'
      if (obj === 'achievementForm=>fileUrl') fileManage = '合同原件扫描件'
      if (obj === 'intellectualProperty') fileManage = '原件扫描件'
      if (obj === 'taxcredit') fileManage = '原件扫描件'
      if (obj === 'other') fileManage = '资料附件'
      // 文件重命名
      const oldFileName = file.name
      const oldFileNameArr = oldFileName.split('.')
      let newSimpleFileName = ''
      for (let i = 0; i < oldFileNameArr.length - 1; i++) {
        newSimpleFileName += oldFileNameArr[i]
      }
      newSimpleFileName = newSimpleFileName.replace(/[^\u4e00-\u9fa50-9a-zA-Z]+/g, '')
      const newFileName = newSimpleFileName + '.' + oldFileNameArr[oldFileNameArr.length - 1]
      const newFile = new File([file], newFileName, { // 文件不能直接重命名， 要重新new 一个file
        type: file.type
      })
      // 不要文件重命名了
      // const newFile = file
      if (newFile.type === 'application/pdf') {
        vm.pdfList.push(file)
      }
      let isJPG = false
      let isJPGMessage = ''
      if (vm.vipLevelChange === '00010002') {
        isJPG = newFile.type.toLowerCase() === 'image/jpeg' ||
          newFile.type.toLowerCase() === 'image/jpg' ||
          newFile.type.toLowerCase() === 'image/png' ||
          newFile.type.toLowerCase() === 'image/bmp' ||
          newFile.type.toLowerCase() === 'application/pdf'
        isJPGMessage = ' JPG PNG BMP PDF '
        // DPF只能传一个
        if (newFile.type.toLowerCase() === 'application/pdf') {
          if (obj === 'credential' && stringFre('.pdf', vm.credentialForm?.fileUrl) >= pdfMaxNum) isJPG = false
          if (obj === 'honorImage' && stringFre('.pdf', vm.honorImageForm?.fileUrl) >= pdfMaxNum) isJPG = false
          if (obj === 'registeredPersonnel' && stringFre('.pdf', vm.registeredPersonnelForm?.fileUrl) >= pdfMaxNum) isJPG = false
          if (obj === 'achievementForm=>partContractFileUrl' && stringFre('.pdf', vm.achievementForm?.partContractFileUrl) >= pdfMaxNum) isJPG = false
          if (obj === 'achievementForm=>invoiceFileUrl' && stringFre('.pdf', vm.achievementForm?.invoiceFileUrl) >= pdfMaxNum) isJPG = false
          if (obj === 'achievementForm=>fileUrl' && stringFre('.pdf', vm.achievementForm?.fileUrl) >= pdfMaxNum) isJPG = false
          if (obj === 'intellectualProperty' && stringFre('.pdf', vm.intellectualPropertyForm?.fileUrl) >= pdfMaxNum) isJPG = false
          if (obj === 'taxcredit' && stringFre('.pdf', vm.taxcreditForm?.fileUrl) >= pdfMaxNum) isJPG = false
          if (obj === 'other' && stringFre('.pdf', vm.otherForm?.fileUrl) >= pdfMaxNum) isJPG = false
          if (!isJPG) {
            ElMessage.closeAll()
            ElMessage.warning({
              message: 'PDF文件最多只能上传10个!',
              type: 'warning',
              offset: 60
            })
            return false
          }
        }
      } else {
        isJPG = newFile.type.toLowerCase() === 'image/jpeg' ||
          newFile.type.toLowerCase() === 'image/jpg' ||
          newFile.type.toLowerCase() === 'image/png'
        isJPGMessage = ' JPG PNG '
      }
      let maxSize = 0
      if (newFile.type.toLowerCase() === 'application/pdf') maxSize = 20
      if (newFile.type.toLowerCase() !== 'application/pdf') maxSize = 5
      const isLt2M = file.size / 1024 / 1024 < maxSize
      if (!isJPG) {
        ElMessage.closeAll()
        ElMessage.warning({
          message: '上传' + fileManage + '只能是' + isJPGMessage + '格式!',
          type: 'warning',
          offset: 60
        })
        return false
      }
      if (!isLt2M) {
        ElMessage.closeAll()
        ElMessage.warning({
          message: '上传' + fileManage + '大小不能超过 ' + maxSize + 'MB!',
          type: 'warning',
          offset: 60
        })
        return false
      }
      let remainingPdfFileNum = 10
      if (obj === 'credential') remainingPdfFileNum = pdfMaxNum - stringFre('.pdf', vm.credentialForm?.fileUrl)
      if (obj === 'honorImage') remainingPdfFileNum = pdfMaxNum - stringFre('.pdf', vm.honorImageForm?.fileUrl)
      if (obj === 'registeredPersonnel') remainingPdfFileNum = pdfMaxNum - stringFre('.pdf', vm.registeredPersonnelForm?.fileUrl)
      if (obj === 'achievementForm=>partContractFileUrl') remainingPdfFileNum = pdfMaxNum - stringFre('.pdf', vm.achievementForm?.partContractFileUrl)
      if (obj === 'achievementForm=>invoiceFileUrl') remainingPdfFileNum = pdfMaxNum - stringFre('.pdf', vm.achievementForm?.invoiceFileUrl)
      if (obj === 'achievementForm=>fileUrl') remainingPdfFileNum = pdfMaxNum - stringFre('.pdf', vm.achievementForm?.fileUrl)
      if (obj === 'intellectualProperty') remainingPdfFileNum = pdfMaxNum - stringFre('.pdf', vm.intellectualPropertyForm?.fileUrl)
      if (obj === 'taxcredit') remainingPdfFileNum = pdfMaxNum - stringFre('.pdf', vm.taxcreditForm?.fileUrl)
      if (obj === 'other') remainingPdfFileNum = pdfMaxNum - stringFre('.pdf', vm.otherForm?.fileUrl)
      if (newFile.type.toLowerCase() === 'application/pdf' && vm.pdfList.length > remainingPdfFileNum) {
        ElMessage.closeAll()
        ElMessage.warning({
          message: 'PDF文件最多只能上传10个！',
          type: 'warning',
          offset: 60
        })
        return false
      }
      if (isJPG && isLt2M) {
        // 可以上传
        vm.loading = vm.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        const form = new FormData()
        form.append('file', newFile)
        forwardFile(form).then(result => {
          if (vm.loading) {
            vm.loading.close()
            vm.loading = ''
          }
          if (result.code === 0) {
            const fileUrl = result.data?.downPath
            if (!fileUrl) {
              ElMessage.error(fileManage + '上传失败')
            } else {
              if (obj === 'credential') {
                // 资质
                if (vm.credentialForm.fileUrl) {
                  vm.credentialForm.fileUrl += ',' + fileUrl
                } else {
                  vm.credentialForm.fileUrl = fileUrl
                }
                vm.credentialForm.fileList.push({
                  name: newFile.name,
                  url: fileUrl
                })
                vm.$refs.credentialForm.clearValidate('fileUrl')
              } else if (obj === 'honorImage') {
                // 荣誉
                if (vm.honorImageForm.fileUrl) {
                  vm.honorImageForm.fileUrl += ',' + fileUrl
                } else {
                  vm.honorImageForm.fileUrl = fileUrl
                }
                vm.honorImageForm.fileList.push({
                  name: newFile.name,
                  url: fileUrl
                })
                vm.$refs.honorImageForm.clearValidate('fileUrl')
              } else if (obj === 'registeredPersonnel') {
                // 人员
                if (vm.registeredPersonnelForm.fileUrl) {
                  vm.registeredPersonnelForm.fileUrl += ',' + fileUrl
                } else {
                  vm.registeredPersonnelForm.fileUrl = fileUrl
                }
                vm.registeredPersonnelForm.fileList.push({
                  name: newFile.name,
                  url: fileUrl
                })
                vm.$refs.registeredPersonnelForm.clearValidate('fileUrl')
              } else if (obj === 'achievementForm=>partContractFileUrl') {
                // 中标里面的分包合同原件扫描件
                if (vm.achievementForm.partContractFileUrl) {
                  vm.achievementForm.partContractFileUrl += ',' + fileUrl
                } else {
                  vm.achievementForm.partContractFileUrl = fileUrl
                }
                vm.achievementForm.partContractFileList.push({
                  name: newFile.name,
                  url: fileUrl
                })
                vm.$refs.achievementForm.clearValidate('partContractFileUrl')
              } else if (obj === 'achievementForm=>invoiceFileUrl') {
                // 发票原件扫描件
                if (vm.achievementForm.invoiceFileUrl) {
                  vm.achievementForm.invoiceFileUrl += ',' + fileUrl
                } else {
                  vm.achievementForm.invoiceFileUrl = fileUrl
                }
                vm.achievementForm.invoiceFileList.push({
                  name: newFile.name,
                  url: fileUrl
                })
                vm.$refs.achievementForm.clearValidate('invoiceFileUrl')
                vm.achievementFormUrlInput('')
              } else if (obj === 'achievementForm=>fileUrl') {
                // 合同原件扫描件
                if (vm.achievementForm.fileUrl) {
                  vm.achievementForm.fileUrl += ',' + fileUrl
                } else {
                  vm.achievementForm.fileUrl = fileUrl
                }
                vm.achievementForm.fileList.push({
                  name: newFile.name,
                  url: fileUrl
                })
                vm.$refs.achievementForm.clearValidate('fileUrl')
                vm.achievementFormUrlInput('')
              } else if (obj === 'intellectualProperty') {
                // 原件扫描件
                if (vm.intellectualPropertyForm.fileUrl) {
                  vm.intellectualPropertyForm.fileUrl += ',' + fileUrl
                } else {
                  vm.intellectualPropertyForm.fileUrl = fileUrl
                }
                vm.intellectualPropertyForm.fileList.push({
                  name: newFile.name,
                  url: fileUrl
                })
                vm.$refs.intellectualPropertyForm.clearValidate('fileUrl')
              } else if (obj === 'taxcredit') {
                // 原件扫描件
                if (vm.taxcreditForm.fileUrl) {
                  vm.taxcreditForm.fileUrl += ',' + fileUrl
                } else {
                  vm.taxcreditForm.fileUrl = fileUrl
                }
                vm.taxcreditForm.fileList.push({
                  name: newFile.name,
                  url: fileUrl
                })
                vm.$refs.taxcreditForm.clearValidate('fileUrl')
              } else if (obj === 'other') {
                // 原件扫描件
                if (vm.otherForm.fileUrl) {
                  vm.otherForm.fileUrl += ',' + fileUrl
                } else {
                  vm.otherForm.fileUrl = fileUrl
                }
                vm.otherForm.fileList.push({
                  name: newFile.name,
                  url: fileUrl
                })
                vm.$refs.otherForm.clearValidate('fileUrl')
                vm.otherFormUrlInput('')
              }
            }
          } else {
            ElMessage.error(fileManage + '上传失败')
          }
        }).catch(function () {
          ElMessage.error(fileManage + '上传失败')
          if (vm.loading) {
            vm.loading.close()
            vm.loading = ''
          }
        })
        return false
      } else {
        return isJPG && isLt2M
      }
    },
    handlePreview (file) {
      const fileNmae = file.name
      const oldFileNameArr = fileNmae.split('.')
      const fileType = oldFileNameArr[oldFileNameArr.length - 1]
      if (fileType.toString().toLowerCase().indexOf('pdf') !== -1) {
        window.open(file.url, '_black')
      } else {
        this.$viewerApi({
          images: [file.url]
        })
      }
    },
    handleRemove (file, fileList, obj) {
      const vm = this
      // console.log('删除了??')
      if (file.status === 'success') {
        vm.pdfList = []
      }
      let fileUrl = ''
      for (let i = 0; i < fileList.length; i++) {
        if (i === 0) {
          fileUrl += fileList[i].url
        } else {
          fileUrl += ',' + fileList[i].url
        }
      }
      if (obj === 'credential') {
        vm.credentialForm.fileList = JSON.parse(JSON.stringify(fileList))
        vm.credentialForm.fileUrl = fileUrl
      } else if (obj === 'honorImage') {
        vm.honorImageForm.fileList = JSON.parse(JSON.stringify(fileList))
        vm.honorImageForm.fileUrl = fileUrl
      } else if (obj === 'registeredPersonnel') {
        vm.registeredPersonnelForm.fileList = JSON.parse(JSON.stringify(fileList))
        vm.registeredPersonnelForm.fileUrl = fileUrl
      } else if (obj === 'achievementForm=>partContractFileUrl') {
        vm.achievementForm.partContractFileList = JSON.parse(JSON.stringify(fileList))
        vm.achievementForm.partContractFileUrl = fileUrl
        vm.achievementFormUrlInput(vm.achievementForm.url)
      } else if (obj === 'achievementForm=>invoiceFileUrl') {
        vm.achievementForm.invoiceFileList = JSON.parse(JSON.stringify(fileList))
        vm.achievementForm.invoiceFileUrl = fileUrl
        vm.achievementFormUrlInput(vm.achievementForm.url)
      } else if (obj === 'achievementForm=>fileUrl') {
        vm.achievementForm.fileList = JSON.parse(JSON.stringify(fileList))
        vm.achievementForm.fileUrl = fileUrl
        vm.achievementFormUrlInput(vm.achievementForm.url)
      } else if (obj === 'intellectualProperty') {
        vm.intellectualPropertyForm.fileList = JSON.parse(JSON.stringify(fileList))
        vm.intellectualPropertyForm.fileUrl = fileUrl
      } else if (obj === 'taxcredit') {
        vm.taxcreditForm.fileList = JSON.parse(JSON.stringify(fileList))
        vm.taxcreditForm.fileUrl = fileUrl
      } else if (obj === 'other') {
        vm.otherForm.fileList = JSON.parse(JSON.stringify(fileList))
        vm.otherForm.fileUrl = fileUrl
        vm.otherFormUrlInput(vm.otherForm.url)
      }
    },
    // beforeRemove (file, fileList) {
    //   return this.$confirm(`确定移除 ${file.name}？`)
    // },
    handleExceed (files, fileList) {
      ElMessage.closeAll()
      return ElMessage.warning({
        message: '当前限制选择 9 个文件，本次选择了 ' + files.length + ' 个文件，共选择了 ' + (files.length + fileList.length) + ' 个文件',
        type: 'warning'
      })
    },
    clickTag (tag, index) {
      const vm = this
      if (tag.submitState === '1') {
        return
      }
      let form = ''
      if (tag.tagName === 'credential') form = 'credentialForm'
      if (tag.tagName === 'honorImage') form = 'honorImageForm'
      if (tag.tagName === 'registeredPersonnel') form = 'registeredPersonnelForm'
      if (tag.tagName === 'achievement') form = 'achievementForm'
      if (tag.tagName === 'intellectualProperty') form = 'intellectualPropertyForm'
      if (tag.tagName === 'taxcredit') form = 'taxcreditForm'
      if (tag.tagName === 'other') form = 'otherForm'
      if (vm.$refs[form]) vm.$refs[form].resetFields()
      tag.index = index
      vm[form] = JSON.parse(JSON.stringify(tag))
      vm.dialog[tag.tagName] = true
      if (tag.tagName === 'achievement') {
        setTimeout(function () {
          vm.achievementFormUrlInput(vm[form].url)
        }, 500)
      }
    },
    closeTag (tag, index) {
      const vm = this
      // 清理数据
      vm.tags[tag.tagName].splice(index, 1)
    },
    closeAll (name) {
      const vm = this
      vm.tags[name] = []
    },
    // 如果数字最后一位是点就去掉
    formatNum (num) {
      if (num === null || num === undefined) {
        return num
      }
      let thisNum = num.toString()
      console.log(thisNum)
      while (thisNum.substring(thisNum.length - 1, thisNum.length) === '.') {
        thisNum = thisNum.substring(0, thisNum.length - 1)
      }
      return thisNum * 1
    },
    treeToLabel (data, arr) {
      const result = []
      const childrenTreeToLabel = (data, arr, result) => {
        for (let j = 0; j < data.length; j++) {
          if (arr[0] === data[j].value) {
            result.push(data[j].label)
            if (data[j].children && arr.length > 1) {
              childrenTreeToLabel(data[j].children, arr.slice(1), result)
            }
            break
          }
        }
      }
      childrenTreeToLabel(data, arr, result)
      return result.join('>')
    },
    getRelevancyList: function (data, parentId, callback) {
      const arr = []
      const vm = this
      data.forEach((item, index) => {
        let obj = {}
        if (item.parentId === parentId) {
          obj = {
            value: item.id,
            label: item.name,
            parentId: item.parentId
          }
          if (vm.getRelevancyListChild(data, item, item.id).length > 0) {
            obj.children = vm.getRelevancyListChild(data, item, item.id)
          }
          arr.push(obj)
        }
      })
      callback(arr)
    },
    getRelevancyListChild: function (arr, parentItem, parentId) {
      const vm = this
      const arrs = []
      arr.forEach((item, index) => {
        let obj = {}
        if (item.parentId === parentId) {
          if (vm.getRelevancyListChild(arr, item, item.id).length > 0) {
            obj = {
              value: item.id,
              label: item.name,
              parentId: item.parentId,
              children: vm.getRelevancyListChild(arr, item, item.id)
            }
          } else {
            obj = {
              value: item.id,
              label: item.name,
              parentId: item.parentId
            }
          }
          arrs.push(obj)
        }
      })
      return arrs
    },
    initCredentialForm () {
      const vm = this
      vm.credentialForm.submitState = '0'
      vm.credentialForm.credentialName = '' // 资质名称
      vm.credentialForm.credentialNumber = '' // 证书编号
      vm.credentialForm.qualificationGrade = '' // 资质等级
      vm.credentialForm.issuedBy = '' // 发证机构
      vm.credentialForm.issueDate = '' // 有效时间开始
      vm.credentialForm.closingDate = '' // 有效时间结束
      vm.credentialForm.effectiveDate = '' // 有效时间
      vm.credentialForm.fileUrl = '' // 文件地址 多个逗号分隔
      vm.credentialForm.fileList = []
    },
    initHonorImageForm () {
      const vm = this
      vm.honorImageForm.submitState = '0'
      vm.honorImageForm.name = '' // 荣誉名称
      vm.honorImageForm.issueData = '' // 发证时间
      vm.honorImageForm.honorType = '' // 荣誉类型 1:行业荣誉 2:工商荣誉
      vm.honorImageForm.issuedBy = '' // 发证单位
      vm.honorImageForm.fileUrl = '' // 图片
      vm.honorImageForm.fileList = [] // [{name: '', url: ''}]
    },
    initRegisteredPersonnelForm (searchStringId, artisanValue, registeredType, searchString, level) {
      const vm = this
      vm.registeredPersonnelForm.submitState = '0'
      vm.registeredPersonnelForm.name = '' // 人员姓名
      vm.registeredPersonnelForm.registeredType = registeredType // 注册类别 人员下拉的最后一级
      vm.registeredPersonnelForm.level = level // 只有外层的其他人员才填写其他人员 其他为空
      vm.registeredPersonnelForm.certificateNo = '' // 编号、注册号都叫执业印章号
      vm.registeredPersonnelForm.registeredTime = '' // 注册时间
      vm.registeredPersonnelForm.validPeriod = '' // 有效期限
      vm.registeredPersonnelForm.validTime = '' // 有效时间段 选择使用 选完分配给上面两个
      vm.registeredPersonnelForm.registeredProfessional = '' // 注册专业
      vm.registeredPersonnelForm.artisanValue = artisanValue // 人员下拉的第一级
      vm.registeredPersonnelForm.searchString = searchString // 人员下拉的第一级和第二集 注册建造师>一级注册建造师
      vm.registeredPersonnelForm.searchStringId = searchStringId // 人员下拉的第一级和第二集 id>id
      vm.registeredPersonnelForm.fileUrl = ''
      vm.registeredPersonnelForm.fileList = []
    },
    initAchievementForm (obj) {
      const vm = this
      vm.achievementForm.submitState = '0'
      vm.achievementForm.isSubcontract = obj // 1:总包 0:分包
      vm.achievementForm.projectName = '' // 项目名称
      vm.achievementForm.money = '' // 合同金额
      vm.achievementForm.projectConstructor = '' // 建设单位
      vm.achievementForm.timeSuccess = '' // 中标日期 2021-07-01
      vm.achievementForm.url = '' // 中标链接
      vm.achievementForm.fileUrl = '' // 合同原件扫描件
      vm.achievementForm.fileList = [] // [{name: '', url: ''}]
      vm.achievementForm.invoiceFileUrl = '' // 发票原件扫描件
      vm.achievementForm.invoiceFileList = [] // [{name: '', url: ''}]
      vm.achievementForm.partContractFileUrl = '' // 分包合同原件扫描件
      vm.achievementForm.partContractFileList = [] // [{name: '', url: ''}]
    },
    initIntellectualPropertyForm (obj) {
      const vm = this
      vm.intellectualPropertyForm.submitState = '0'
      vm.intellectualPropertyForm.knowledgeType = obj // 软著:SoftwareCopyright 专利:Patent
      vm.intellectualPropertyForm.category = '' // 分类号
      vm.intellectualPropertyForm.name = '' // 软件名称
      vm.intellectualPropertyForm.shortName = '' // 简称
      vm.intellectualPropertyForm.versionNo = '' // 版本号
      vm.intellectualPropertyForm.registerNo = '' // 登记号
      vm.intellectualPropertyForm.publishDate = '' // 2021-07-01 发布日期
      vm.intellectualPropertyForm.registerAperDate = '' // 2021-07-01 登记批准日期
      vm.intellectualPropertyForm.certificateNumber = '' // 证书编号
      vm.intellectualPropertyForm.title = '' // 产权名称
      vm.intellectualPropertyForm.kindcodedesc = '' // PCT发明
      vm.intellectualPropertyForm.releaseDate = '' // 2021-07-01 发证日期
      vm.intellectualPropertyForm.fileUrl = ''
      vm.intellectualPropertyForm.fileList = []
    },
    initTaxcreditForm () {
      const vm = this
      vm.taxcreditForm.submitState = '0'
      vm.taxcreditForm.year = '' // 评价年度
      vm.taxcreditForm.level = '' // 纳税信用等级
      vm.taxcreditForm.announcementUrl = '' // 公告连接
      vm.taxcreditForm.fileUrl = ''
      vm.taxcreditForm.fileList = []
    },
    initOtherForm () {
      const vm = this
      vm.otherForm.submitState = '0'
      vm.otherForm.informationName = '' // 资料名称
      vm.otherForm.url = '' // 链接
      vm.otherForm.remark = '' // 备注
      vm.otherForm.fileUrl = ''
      vm.otherForm.fileList = []
    },
    // 中标的中标链接值变化处罚
    achievementFormUrlInput (obj) {
      const vm = this
      if (vm.achievementForm.isSubcontract === '1') {
        // 总包
        if (obj) {
          vm.achievementRules.url[0].required = true
          vm.achievementRules.fileUrl[0].required = false
          vm.achievementRules.invoiceFileUrl[0].required = false
          vm.$refs.achievementForm.clearValidate('fileUrl')
          vm.$refs.achievementForm.clearValidate('invoiceFileUrl')
        }
        if (vm.achievementForm.fileUrl || vm.achievementForm.invoiceFileUrl) {
          vm.achievementRules.url[0].required = false
          vm.$refs.achievementForm.clearValidate('url')
          vm.achievementRules.fileUrl[0].required = true
          vm.achievementRules.invoiceFileUrl[0].required = true
        }
      } else if (vm.achievementForm.isSubcontract === '0') {
        // 分包
        if (obj) {
          vm.achievementRules.url[0].required = true
          vm.achievementRules.fileUrl[0].required = false
          vm.$refs.achievementForm.clearValidate('fileUrl')
        }
        if (vm.achievementForm.fileUrl) {
          vm.achievementRules.url[0].required = false
          vm.$refs.achievementForm.clearValidate('url')
          vm.achievementRules.fileUrl[0].required = true
        }
      }
    },
    // 其他中的链接
    otherFormUrlInput (obj) {
      const vm = this
      if (obj) {
        vm.otherRules.url[0].required = true
        vm.otherRules.fileUrl[0].required = false
        vm.$refs.otherForm.clearValidate('fileUrl')
      }
      if (vm.otherForm.fileUrl) {
        vm.otherRules.url[0].required = false
        vm.$refs.otherForm.clearValidate('url')
        vm.otherRules.fileUrl[0].required = true
      }
    },
    knowledgeTypeChange (obj) {
      const vm = this
      vm.initIntellectualPropertyForm(obj)
      setTimeout(function () {
        vm.$refs.intellectualPropertyForm.clearValidate()
      }, 100)
    },
    isSubcontractChanage (obj) {
      const vm = this
      vm.initAchievementForm(obj)
      setTimeout(function () {
        vm.$refs.achievementForm.clearValidate()
      }, 100)
    },
    cascaderHandleChange (item) {
      const vm = this
      const relevancyName = vm.treeToLabel(vm.cascaderOptions, item)
      const relevancyNameArr = relevancyName.split('>')
      vm.registeredPersonnelForm.searchString = relevancyName
      vm.registeredPersonnelForm.artisanValue = relevancyNameArr[0]
      vm.registeredPersonnelForm.registeredType = relevancyNameArr[relevancyNameArr.length - 1]
      const searchStringId = item
      const artisanValue = relevancyNameArr[0]
      const registeredType = relevancyName === '其他人员' ? '' : relevancyNameArr[relevancyNameArr.length - 1]
      const searchString = relevancyName
      let level = ''
      if (relevancyName === '其他人员') {
        level = '其他人员'
      }
      vm.initRegisteredPersonnelForm(searchStringId, artisanValue, registeredType, searchString, level)
      setTimeout(function () {
        vm.$refs.registeredPersonnelForm.clearValidate()
      }, 100)
    },
    getTagsType (submitState) {
      if (submitState === '0') return ''
      if (submitState === '1') return 'success'
      if (submitState === '2') return 'danger'
    },
    validTimeChange (item) {
      const vm = this
      console.log(JSON.stringify(item))
      if (item && item.length === 2) {
        vm.registeredPersonnelForm.registeredTime = parseTime(item[0], '{y}-{m}-{d}')
        vm.registeredPersonnelForm.validPeriod = parseTime(item[1], '{y}-{m}-{d}')
      }
    },
    // 终极提交 我看到了终极
    ultimateSubmit () {
      const vm = this
      vm.$store.dispatch('setDataMaintainModel', null)
      vm.submitSuccessNum = 0
      vm.submitErrorNum = 0
      vm.ultimateSubmitLoading.credential = true
      vm.ultimateSubmitLoading.honorImage = true
      vm.ultimateSubmitLoading.registeredPersonnel = true
      vm.ultimateSubmitLoading.achievement = true
      vm.ultimateSubmitLoading.intellectualProperty = true
      vm.ultimateSubmitLoading.taxcredit = true
      vm.ultimateSubmitLoading.other = true
      // 资质证书
      const credentialModels = vm.getModelNoSubmit(vm.tags.credential)
      const credentialUrl = '/CompanyCreditEnhancement/insertCredentials'
      vm.ultimateSubmitVarious(credentialModels, credentialUrl, 'credential')
      // 荣誉证书
      const honorImageModels = vm.getModelNoSubmit(vm.tags.honorImage)
      const honorImageUrl = '/CompanyCreditEnhancement/insertHonorImages'
      vm.ultimateSubmitVarious(honorImageModels, honorImageUrl, 'honorImage')
      // 技术人员
      const registeredPersonnelModels = vm.getModelNoSubmit(vm.tags.registeredPersonnel)
      const registeredPersonnelUrl = '/CompanyCreditEnhancement/insertPersonnels'
      vm.ultimateSubmitVarious(registeredPersonnelModels, registeredPersonnelUrl, 'registeredPersonnel')
      // 项目业绩
      const achievementModels = vm.getModelNoSubmit(vm.tags.achievement)
      const achievementUrl = '/CompanyCreditEnhancement/insertAchievements'
      vm.ultimateSubmitVarious(achievementModels, achievementUrl, 'achievement')
      // 知识产权证书
      const intellectualPropertyModels = vm.getModelNoSubmit(vm.tags.intellectualProperty)
      const intellectualPropertyUrl = '/CompanyCreditEnhancement/insertIntellectualPropertyRights'
      vm.ultimateSubmitVarious(intellectualPropertyModels, intellectualPropertyUrl, 'intellectualProperty')
      // 税务荣誉证书
      const taxcreditModels = vm.getModelNoSubmit(vm.tags.taxcredit)
      const taxcreditUrl = '/CompanyCreditEnhancement/insertTaxcredits'
      vm.ultimateSubmitVarious(taxcreditModels, taxcreditUrl, 'taxcredit')
      // 其他
      const otherModels = vm.getModelNoSubmit(vm.tags.other)
      const otherUrl = '/CompanyCreditEnhancement/insertOthers'
      vm.ultimateSubmitVarious(otherModels, otherUrl, 'other')
      if (credentialModels.length === 0 &&
        honorImageModels.length === 0 &&
        registeredPersonnelModels.length === 0 &&
        achievementModels.length === 0 &&
        intellectualPropertyModels.length === 0 &&
        taxcreditModels.length === 0 &&
        otherModels.length === 0) {
        return ElMessage('没有需要核实的数据')
      }
    },
    ultimateSubmitVarious (models, url, tagName) {
      const vm = this
      // 提交
      if (models.length > 0) {
        const data = {
          // data: {
          //   maps: models
          // },
          // dateBackgroundRequestType: 'post',
          // dateBackgroundUrl: url
          maps: models,
          pro: vm.cityName
        }
        gloubeURl(data, url).then(result => {
          if (result.code === 0) {
            const data = result.data ? result.data : {}
            // 深复制一份 因为该状态会影响下一次循环的结果
            const model = JSON.parse(JSON.stringify(vm.tags[tagName]))
            for (const key in data) {
              let index = -1
              for (let i = 0; i < model.length; i++) {
                // 只有没有提交成功的才算计数去匹配
                if (model[i].submitState !== '1') {
                  index++
                }
                if (model[i].submitState !== '1' && index === key * 1) { // 0:未提交 1提交成功 2提交失败
                  data[key] ? vm.submitSuccessNum++ : vm.submitErrorNum++
                  vm.tags[tagName][i].submitState = data[key] ? '1' : '2'
                  break
                }
              }
            }
          } else {
            vm.submitErrorNum += models.length
            for (let i = 0; i < vm.tags[tagName].length; i++) {
              if (vm.tags[tagName][i].submitState !== '1') {
                vm.tags[tagName][i].submitState = '2'
              }
            }
          }
          vm.ultimateSubmitLoading[tagName] = false
          vm.ultimateSubmitEnd()
        }).catch(function () {
          vm.submitErrorNum += models.length
          vm.ultimateSubmitLoading[tagName] = false
          vm.ultimateSubmitEnd()
        })
      } else {
        vm.ultimateSubmitLoading[tagName] = false
        // vm.ultimateSubmitEnd()
      }
    },
    // 获取各类中未提交的
    getModelNoSubmit (list) {
      const models = []
      for (let i = 0; i < list.length; i++) {
        if (list[i].submitState !== '1') { // 0:未提交 1提交成功 2提交失败
          models.push(list[i])
        }
      }
      return models
    },
    // 终极提交结束之后的提示
    ultimateSubmitEnd () {
      const vm = this
      if (!vm.ultimateSubmitLoading.credential &&
        !vm.ultimateSubmitLoading.honorImage &&
        !vm.ultimateSubmitLoading.registeredPersonnel &&
        !vm.ultimateSubmitLoading.achievement &&
        !vm.ultimateSubmitLoading.intellectualProperty &&
        !vm.ultimateSubmitLoading.taxcredit &&
        !vm.ultimateSubmitLoading.other) {
        if (vm.submitSuccessNum === 0) { // 没有成功的
          vm.$alert('提交失败，其中有' + vm.submitErrorNum + '条提交失败，请重新填写数据后进行提交，核对通过后，将提醒您并且在个人中心的【维护记录】进行展示', '温馨提示', {
            confirmButtonText: '确定'
          })
          // ElMessage.error('提交失败，其中有' + vm.submitErrorNum + '条提交失败，请重新填写数据后进行提交，核对通过后，将提醒您并且在个人中心的【维护记录】进行展示')
        } else if (vm.submitErrorNum === 0) {
          vm.$alert('提交成功，请等待工作人员进行核对，核对通过后，将提醒您并且在个人中心的【维护记录】进行展示', '温馨提示', {
            confirmButtonText: '确定'
          })
          // ElMessage.success({
          //   message: '提交成功，请等待工作人员进行核对，核对通过后，将提醒您并且在个人中心的【维护记录】进行展示',
          //   type: 'success'
          // })
        } else {
          vm.$alert('提交成功' + vm.submitSuccessNum + '条，有' + vm.submitErrorNum + '条提交失败，请等待工作人员进行核对，核对通过后，将提醒您并且在个人中心的【维护记录】进行展示', {
            confirmButtonText: '确定'
          })
          // ElMessage.warning({
          //   message: '提交成功' + vm.submitSuccessNum + '条，有' + vm.submitErrorNum + '条提交失败，请等待工作人员进行核对，核对通过后，将提醒您并且在个人中心的【维护记录】进行展示',
          //   type: 'warning'
          // })
        }
      }
    },
    getKnowledgeType (knowledgeType) {
      if (knowledgeType === 'Patent') return '专利'
      if (knowledgeType === 'SoftwareCopyright') return '软件著作权'
    },
    getKnowledgeName (tag) {
      if (tag.knowledgeType === 'Patent') return tag.title
      if (tag.knowledgeType === 'SoftwareCopyright') return tag.name
    },
    // 初始化个人中心的维护记录中带过来的数据
    initDataMaintainModel () {
      const vm = this
      console.log(vm.dataMaintainModel)
      let dataMaintainModel = null
      if (vm.dataMaintainModel) {
        dataMaintainModel = JSON.parse(vm.dataMaintainModel)
      }
      if (dataMaintainModel?.key === 'credential') {
        let fileList = []
        if (dataMaintainModel.value.fileUrl) {
          const fileUrlArr = dataMaintainModel.value.fileUrl.split(',')
          fileList = []
          for (let i = 0; i < fileUrlArr.length; i++) {
            fileList.push({
              name: fileUrlArr[i].split('_')[fileUrlArr[i].split('_').length - 1],
              url: fileUrlArr[i]
            })
          }
        }
        vm.credentialForm.credentialName = dataMaintainModel?.value?.credentialName // 资质名称
        vm.credentialForm.credentialNumber = dataMaintainModel?.value?.credentialNumber // 证书编号
        vm.credentialForm.qualificationGrade = dataMaintainModel?.value?.qualificationGrade // 资质等级
        vm.credentialForm.issuedBy = dataMaintainModel?.value?.issuedBy // 发证机构
        vm.credentialForm.issueDate = parseTime(dataMaintainModel?.value?.issueDate, '{y}-{m}-{d}') // 有效时间开始
        vm.credentialForm.closingDate = parseTime(dataMaintainModel?.value?.closingDate, '{y}-{m}-{d}') // 有效时间结束
        if (dataMaintainModel?.value?.issueDate && dataMaintainModel?.value?.closingDate) {
          vm.credentialForm.effectiveDate = [dataMaintainModel?.value?.issueDate, dataMaintainModel?.value?.closingDate] // 有效时间
        }
        vm.credentialForm.fileUrl = dataMaintainModel?.value?.fileUrl // 文件地址 多个逗号分隔
        vm.credentialForm.fileList = fileList // [{name: '', url: ''}]
        // 加入tab中
        vm.credentialForm.submitState = 0
        vm.tags.credential.push(JSON.parse(JSON.stringify(vm.credentialForm)))
      } else if (dataMaintainModel?.key === 'honorImage') {
        let fileList = []
        if (dataMaintainModel.value.fileUrl) {
          const fileUrlArr = dataMaintainModel.value.fileUrl.split(',')
          fileList = []
          for (let i = 0; i < fileUrlArr.length; i++) {
            fileList.push({
              name: fileUrlArr[i].split('_')[fileUrlArr[i].split('_').length - 1],
              url: fileUrlArr[i]
            })
          }
        }
        vm.honorImageForm.name = dataMaintainModel?.value?.name // 荣誉名称
        if (dataMaintainModel?.value?.issueData) {
          console.log(dataMaintainModel?.value?.issueData.substring(0, 4) + '-' + dataMaintainModel?.value?.issueData.substring(5, 7) + '-01 00:00:00')
          vm.honorImageForm.issueData = new Date(dataMaintainModel?.value?.issueData.substring(0, 4) + '-' + dataMaintainModel?.value?.issueData.substring(5, 7) + '-01 00:00:00') // 发证时间
        }
        let honorType = ''
        if (dataMaintainModel?.value?.honorType === '行业荣誉') honorType = '1'
        if (dataMaintainModel?.value?.honorType === '工商荣誉') honorType = '2'
        vm.honorImageForm.honorType = honorType // 荣誉类型 1:行业荣誉 2:工商荣誉
        vm.honorImageForm.issuedBy = dataMaintainModel?.value?.issuedBy // 发证单位
        vm.honorImageForm.fileUrl = dataMaintainModel?.value?.fileUrl // 文件地址 多个逗号分隔
        vm.honorImageForm.fileList = fileList // [{name: '', url: ''}]
        // 加入tab中
        vm.honorImageForm.submitState = 0
        vm.tags.honorImage.push(JSON.parse(JSON.stringify(vm.honorImageForm)))
      } else if (dataMaintainModel?.key === 'registeredPersonnel') {
        let fileList = []
        if (dataMaintainModel.value.fileUrl) {
          const fileUrlArr = dataMaintainModel.value.fileUrl.split(',')
          fileList = []
          for (let i = 0; i < fileUrlArr.length; i++) {
            fileList.push({
              name: fileUrlArr[i].split('_')[fileUrlArr[i].split('_').length - 1],
              url: fileUrlArr[i]
            })
          }
        }
        vm.registeredPersonnelForm.name = dataMaintainModel?.value?.name // 人员姓名
        vm.registeredPersonnelForm.level = dataMaintainModel?.value?.level // 只有外层的其他人员才填写其他人员 其他为空
        vm.registeredPersonnelForm.certificateNo = dataMaintainModel?.value?.certificateno // 编号、注册号都叫执业印章号
        if (dataMaintainModel?.value?.registeredTime) {
          vm.registeredPersonnelForm.registeredTime = dataMaintainModel?.value?.registeredTime // 注册时间
        }
        if (dataMaintainModel?.value?.validPeriod) {
          vm.registeredPersonnelForm.validPeriod = dataMaintainModel?.value?.validPeriod // 有效期限
        }
        if (dataMaintainModel?.value?.registeredTime && dataMaintainModel?.value?.validPeriod) {
          vm.registeredPersonnelForm.validTime = dataMaintainModel?.value?.validTime // 有效时间段 选择使用 选完分配给上面两个
        }
        vm.registeredPersonnelForm.registeredProfessional = dataMaintainModel?.value?.registeredProfessional // 注册专业
        vm.registeredPersonnelForm.fileUrl = dataMaintainModel?.value?.fileUrl // 文件地址 多个逗号分隔
        vm.registeredPersonnelForm.fileList = fileList // [{name: '', url: ''}]
        if (dataMaintainModel?.value?.registeredType) {
          const data = {
            // data: {
            //   qualificationName: dataMaintainModel?.value?.registeredType
            // },
            // dateBackgroundRequestType: 'get',
            // dateBackgroundUrl: '/artisanClassification/getArtisanRelevance'
            qualificationName: dataMaintainModel?.value?.registeredType
          }
          getArtisanRelevance(data).then(result => {
            if (result.code === 0) {
              const dataList = result.data ? result.data : []
              let oneId = ''
              let oneName = ''
              let twoId = ''
              let twoName = ''
              let twoelevancyId = ''
              // 是否只有一层
              const theRadio = dataMaintainModel?.value?.registeredType === '注册造价工程师' ||
                dataMaintainModel?.value?.registeredType === '注册土木工程师（岩土）' ||
                dataMaintainModel?.value?.registeredType === '注册化工工程师' ||
                dataMaintainModel?.value?.registeredType === '注册监理工程师' ||
                (dataMaintainModel?.value?.registeredType === '其他人员' && dataMaintainModel?.value?.level === '其他人员')
              console.log(theRadio)
              if (theRadio) {
                for (let i = 0; i < dataList.length; i++) {
                  if (dataList[i].relevancyId === '0' && theRadio && dataList[i].qualificationName === dataMaintainModel?.value.registeredType) {
                    oneId = dataList[i].blArtisanRelevancyId
                    oneName = dataList[i].qualificationName
                  }
                }
              } else {
                for (let i = 0; i < dataList.length; i++) {
                  if (dataList[i].qualificationName === dataMaintainModel?.value?.registeredType && dataList[i].relevancyId !== '0') {
                    twoId = dataList[i].blArtisanRelevancyId
                    twoName = dataList[i].qualificationName
                    twoelevancyId = dataList[i].relevancyId
                    console.log('two: ' + twoId + ' --- ' + twoName + ' --- ' + twoelevancyId)
                  }
                }
                for (let i = 0; i < dataList.length; i++) {
                  if (dataList[i].relevancyId === '0' && dataList[i].blArtisanRelevancyId === twoelevancyId) {
                    oneId = dataList[i].blArtisanRelevancyId
                    oneName = dataList[i].qualificationName
                    console.log('one: ' + oneId + ' --- ' + oneName)
                  }
                }
              }
              let registeredType = ''
              if (twoName) registeredType = twoName
              if (!twoName) registeredType = oneName
              const artisanValue = oneName
              let searchString = ''
              if (oneName) searchString += oneName
              if (twoName && oneName) searchString += '>' + twoName
              if (twoName && !oneName) searchString += twoName
              const searchStringId = []
              if (oneId) searchStringId.push(oneId)
              if (twoId) searchStringId.push(twoId)
              console.log(registeredType)
              console.log(artisanValue)
              console.log(searchString)
              console.log(searchStringId)
              vm.registeredPersonnelForm.registeredType = registeredType // 注册类别 人员下拉的最后一级
              vm.registeredPersonnelForm.artisanValue = artisanValue // 人员下拉的第一级
              vm.registeredPersonnelForm.searchString = searchString // 人员下拉的第一级和第二集 注册建造师>一级注册建造师
              vm.registeredPersonnelForm.searchStringId = searchStringId // 人员下拉的第一级和第二集 id>id
              // 加入tab中
              vm.registeredPersonnelForm.submitState = 0
              vm.tags.registeredPersonnel.push(JSON.parse(JSON.stringify(vm.registeredPersonnelForm)))
            }
          }).catch(function () {
          })
        } else {
          // 加入tab中
          vm.registeredPersonnelForm.submitState = 0
          vm.tags.registeredPersonnel.push(JSON.parse(JSON.stringify(vm.registeredPersonnelForm)))
        }
      } else if (dataMaintainModel?.key === 'achievement') {
        let fileList = []
        let invoiceFileList = []
        let partContractFileList = []
        if (dataMaintainModel.value.fileUrl) {
          const fileUrlArr = dataMaintainModel.value.fileUrl.split(',')
          fileList = []
          for (let i = 0; i < fileUrlArr.length; i++) {
            fileList.push({
              name: fileUrlArr[i].split('_')[fileUrlArr[i].split('_').length - 1],
              url: fileUrlArr[i]
            })
          }
        }
        if (dataMaintainModel.value.invoiceFileUrl) {
          const invoiceFileUrlArr = dataMaintainModel.value.invoiceFileUrl.split(',')
          invoiceFileList = []
          for (let i = 0; i < invoiceFileUrlArr.length; i++) {
            invoiceFileList.push({
              name: invoiceFileUrlArr[i].split('_')[invoiceFileUrlArr[i].split('_').length - 1],
              url: invoiceFileUrlArr[i]
            })
          }
        }
        if (dataMaintainModel.value.partContractFileUrl) {
          const partContractFileUrlArr = dataMaintainModel.value.partContractFileUrl.split(',')
          partContractFileList = []
          for (let i = 0; i < partContractFileUrlArr.length; i++) {
            partContractFileList.push({
              name: partContractFileUrlArr[i].split('_')[partContractFileUrlArr[i].split('_').length - 1],
              url: partContractFileUrlArr[i]
            })
          }
        }
        let isSubcontract = ''
        if (dataMaintainModel?.value?.isSubcontract === '总包') isSubcontract = '1'
        if (dataMaintainModel?.value?.isSubcontract === '分包') isSubcontract = '0'
        vm.achievementForm.isSubcontract = isSubcontract // 1:总包 0:分包
        vm.achievementForm.projectName = dataMaintainModel?.value?.projectName // 项目名称
        vm.achievementForm.money = dataMaintainModel?.value?.money?.toString() // 合同金额
        vm.achievementForm.projectConstructor = dataMaintainModel?.value?.projectConstructor // 建设单位
        if (dataMaintainModel?.value?.timeSuccess) {
          vm.achievementForm.timeSuccess = new Date(dataMaintainModel?.value?.timeSuccess + ' 00:00:00') // 中标日期 2021-07-01
        }
        vm.achievementForm.url = dataMaintainModel?.value?.url // 中标链接
        vm.achievementForm.fileUrl = dataMaintainModel?.value?.fileUrl // 文件地址 多个逗号分隔
        vm.achievementForm.fileList = fileList // [{name: '', url: ''}]
        vm.achievementForm.invoiceFileUrl = dataMaintainModel?.value?.invoiceFileUrl // 文件地址 多个逗号分隔
        vm.achievementForm.invoiceFileList = invoiceFileList // [{name: '', url: ''}]
        vm.achievementForm.partContractFileUrl = dataMaintainModel?.value?.partContractFileUrl // 文件地址 多个逗号分隔
        vm.achievementForm.partContractFileList = partContractFileList // [{name: '', url: ''}]
        // 加入tab中
        vm.achievementForm.submitState = 0
        vm.tags.achievement.push(JSON.parse(JSON.stringify(vm.achievementForm)))
      } else if (dataMaintainModel?.key === 'intellectualProperty') {
        let fileList = []
        if (dataMaintainModel.value.fileUrl) {
          const fileUrlArr = dataMaintainModel.value.fileUrl.split(',')
          fileList = []
          for (let i = 0; i < fileUrlArr.length; i++) {
            fileList.push({
              name: fileUrlArr[i].split('_')[fileUrlArr[i].split('_').length - 1],
              url: fileUrlArr[i]
            })
          }
        }
        let knowledgeType = ''
        if (dataMaintainModel?.value?.name) knowledgeType = 'SoftwareCopyright'
        if (dataMaintainModel?.value?.title) knowledgeType = 'Patent'
        vm.intellectualPropertyForm.knowledgeType = knowledgeType // 软著:SoftwareCopyright 专利:Patent
        vm.intellectualPropertyForm.category = dataMaintainModel?.value?.category // 分类号
        vm.intellectualPropertyForm.name = dataMaintainModel?.value?.name // 软件名称
        vm.intellectualPropertyForm.shortName = dataMaintainModel?.value?.shortName // 简称
        vm.intellectualPropertyForm.versionNo = dataMaintainModel?.value?.versionNo // 版本号
        vm.intellectualPropertyForm.registerNo = dataMaintainModel?.value?.registerNo // 登记号
        if (dataMaintainModel?.value?.publishDate) {
          vm.intellectualPropertyForm.publishDate = new Date(dataMaintainModel?.value?.publishDate) // 2021-07-01 发布日期
        }
        if (dataMaintainModel?.value?.registerAperDate) {
          vm.intellectualPropertyForm.registerAperDate = new Date(dataMaintainModel?.value?.registerAperDate) // 2021-07-01 登记批准日期
        }
        // 一下专利使用
        vm.intellectualPropertyForm.certificateNumber = dataMaintainModel?.value?.certificateNumber // 证书编号
        vm.intellectualPropertyForm.title = dataMaintainModel?.value?.title // 产权名称
        vm.intellectualPropertyForm.kindcodedesc = dataMaintainModel?.value?.kindcodedesc // PCT发明
        if (dataMaintainModel?.value?.releaseDate) {
          vm.intellectualPropertyForm.releaseDate = new Date(dataMaintainModel?.value?.releaseDate + ' 00:00:00') // 2021-07-01 发证日期
        }
        vm.intellectualPropertyForm.fileUrl = dataMaintainModel?.value?.fileUrl // 文件地址 多个逗号分隔
        vm.intellectualPropertyForm.fileList = fileList // [{name: '', url: ''}]
        // 加入tab中
        vm.intellectualPropertyForm.submitState = 0
        vm.tags.intellectualProperty.push(JSON.parse(JSON.stringify(vm.intellectualPropertyForm)))
      }
    }
  }
}
</script>

<style scoped>
.uploadButton{
  margin: 30px 0px 30px 0px;
  width: 80px;
}
.link-class{
  cursor: pointer;
}
.link-class:hover{
  font-weight: bold;
}
/deep/ .el-dialog__header {
  border-bottom: 1px solid #ebebeb !important;
}
.btn-style {
  border: 1px solid #fff;
  border-radius: 15px;
  padding: 5px 10px;
  cursor: pointer;
  background-color: red;
  color: #fff;
  animation:mymove 2s infinite;
  -webkit-animation:mymove 2s infinite; /*Safari and Chrome*/
  animation-direction:alternate;/*轮流反向播放动画。*/
  animation-timing-function: ease-in-out; /*动画的速度曲线*/
  /* Safari 和 Chrome */
  -webkit-animation:mymove 2s infinite;
  -webkit-animation-direction:alternate;/*轮流反向播放动画。*/
  -webkit-animation-timing-function: ease-in-out; /*动画的速度曲线*/
}
@keyframes mymove
{
  0%{
  transform: scale(1);  /*开始为原始大小*/
  }
  25%{
      transform: scale(1.5); /*放大1.1倍*/
  }
  50%{
      transform: scale(1);
  }
  75%{
      transform: scale(1.5);
  }
}
@-webkit-keyframes mymove /*Safari and Chrome*/
{
  0%{
  transform: scale(1);  /*开始为原始大小*/
  }
  25%{
      transform: scale(1.5); /*放大1.1倍*/
  }
  50%{
      transform: scale(1);
  }
  75%{
      transform: scale(1.5);
  }
}
.font-pay {
  font-size: 16px;
}
</style>
